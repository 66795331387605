import botInfoStyles from './styles/botInfo.module.scss'

export default function BotInfo (props) {
  const { close } = props
  return (
    <div className={botInfoStyles.container}>
      <h2>
        Welcome to the TokenTiger bot, this bot is capable of making an NFT of
        an image shared in a group. You need to have an account here on
        TokenTiger to make use of the commands.
      </h2>

      <h3>To create an NFT using this bot:</h3>
      <ol>
        <li>Create an account here on TokenTiger.</li>
        <li>
          Use your TokenTiger credentials (email and password) to DM the bot the{' '}
          <code>/login</code>  command.
        </li>
        <li>
          Reply any image in the group with the <code>/maketoken</code> command
          to create an NFT of the image.
        </li>
      </ol>
      <h3>Available commands:</h3>
      <ul>
        <li>
          <span>
            The <code>/help</code> or <code>/start</code> command brings up this
            help message.
          </span>
        </li>
        <li>
          <span>
            The <code>/login</code> <code>(email)</code> <code>(password)</code>{' '}
            command logs in to your TokenTiger account. This command can be
            accessed only as a DM to the bot.
          </span>
        </li>
        <li>
          <span>
            The <code>/collections</code> command list your TokenTiger
            collections. This command can be accessed only as a DM to the bot.
          </span>
        </li>
        <li>
          <span>
            The <code>/maketoken</code> command can be used to reply an image in
            a group, this command will initiate the creation of an NFT here on
            TokenTiger{' '}
          </span>
        </li>
        <li>
          <span>
            The <code>/getcredits</code> command can be used to recharge your daily free credits.
            This command can be accessed only as a DM to the bot.{' '}
          </span>
        </li>
      </ul>

      <button className={botInfoStyles.closeBtn} onClick={close}>
        Close
      </button>
    </div>
  )
}
