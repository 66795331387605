import React, { useState } from 'react'
import { getUser } from '../services/auth'
import { updateUser } from '../services/user'
import xmark from '../components/assets/xmark.svg'
import profileInfoStyles from './styles/profileInfo.module.scss'

import * as EmailValidator from 'email-validator'
import { toast } from 'react-toastify'
import { Formik } from 'formik' // Formik documentation https://formik.org/docs/overview

// Spinner
import PropagateLoader from 'react-spinners/PropagateLoader'

// Redux
import actions from '../redux/actions'
const { useDispatch, updateUsername } = actions

export default function ProfileInfo (props) {
  const { close } = props
  const user = getUser()
  const dispatch = useDispatch()
  const [inFetch, setInFetch] = useState(false)

  // Email validator
  const validateEmail = (values) => {
    const errors = {}
    if (!values.email) {
      errors.email = 'Required'
    } else if (!EmailValidator.validate(values.email)) {
      errors.email = 'Invalid email address.'
    }
    return errors
  }

  // Password validator
  const validatePassword = (values) => {
    const errors = {}
    if (!values.newPassword) {
      errors.newPassword = 'Required'
    }
    return errors
  }

  // Username validator
  const validateUsername = (values) => {
    const errors = {}
    if (!values.username) {
      errors.username = 'Required'
    }
    return errors
  }

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
  // handle email form
  const submitUpdateEmail = async (values) => {
    try {
      // show spinner
      setInFetch(true)

      await sleep(1000)
      const result = await updateUser({ email: values.email, _id: user._id })

      console.log('result', result)
      setInFetch(false)
      toast.success('Success!')
    } catch (error) {
      console.warn('Error in profileInfo/submit()', error)
      setInFetch(false)

      if (
        error.response &&
        error.response.data &&
        error.response.data.match('duplicate key error')
      ) {
        toast.error('Email already exist!')
      } else {
        toast.error('Error! : ' + error.message)
      }
    }
  }

  // handle pasword form
  const submitUpdatePassword = async (values) => {
    try {
      // show spinner
      setInFetch(true)

      await sleep(1000)
      const result = await updateUser({ password: values.newPassword, _id: user._id })

      console.log('result', result)
      setInFetch(false)
      toast.success('Password changed successfully!')
    } catch (error) {
      console.warn('Error in profileInfo/submitUpdatePassword()', error)
      setInFetch(false)

      toast.error('Error! : ' + error.message)
    }
  }

  // handle userName form
  const submitUpdateUserName = async (values) => {
    try {
      // show spinner
      setInFetch(true)

      await sleep(1000)
      const result = await updateUser({ username: values.username, _id: user._id })

      // set username into redux store
      dispatch(updateUsername(values.username))

      console.log('result', result)
      setInFetch(false)
      toast.success('Username changed successfully!')
    } catch (error) {
      console.warn('Error in profileInfo/submitUpdateUserName()', error)
      setInFetch(false)

      toast.error('Error! : ' + error.message)
    }
  }

  return (
    <div className={profileInfoStyles.container}>
      <img
        className={profileInfoStyles.closeBtn}
        src={xmark}
        alt='close icon'
        onKeyDown={close}
        onClick={close}
      />
      <div className={profileInfoStyles.proBtn}>
        <span>Pro mode:</span>
        <div className={profileInfoStyles.toggleBtn}>
          <input disabled type='checkbox' id='switch3' />
          <label htmlFor='switch3'>Toggle</label>
        </div>
      </div>
      {!inFetch && (
        <>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={submitUpdateEmail}
            validate={validateEmail}
          >
            {(props) => {
              const { values, errors, handleChange, handleSubmit } =
                props
              return (
                <div className={profileInfoStyles.emailSection}>
                  <div className={profileInfoStyles.formInfo}>
                    <form onSubmit={handleSubmit}>
                      <label htmlFor='email'>Email</label>
                      <input
                        id='email'
                        type='text'
                        placeholder={user.email}
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <span className={profileInfoStyles.inputFeedback}>
                          {errors.email}
                        </span>
                      )}
                      <button type='submit'>Update Email Address</button>
                    </form>
                  </div>
                </div>
              )
            }}
          </Formik>

          <Formik
            initialValues={{ newPassword: '' }}
            onSubmit={submitUpdatePassword}
            validate={validatePassword}
          >
            {(props) => {
              const { values, errors, handleChange, handleSubmit } =
                props
              return (
                <div className={profileInfoStyles.passwordSection}>
                  <div className={profileInfoStyles.formInfo}>
                    <form onSubmit={handleSubmit}>
                      <label htmlFor='newPassword'>Password</label>
                      <input
                        id='newPassword'
                        type='password'
                        placeholder='Enter new password'
                        value={values.newPassword}
                        onChange={handleChange}

                      />
                      {errors.newPassword && (
                        <span className={profileInfoStyles.inputFeedback}>
                          {errors.newPassword}
                        </span>
                      )}
                      <button type='submit'>Change Password</button>
                    </form>
                  </div>

                </div>
              )
            }}
          </Formik>

          <Formik
            initialValues={{ username: '' }}
            onSubmit={submitUpdateUserName}
            validate={validateUsername}
          >
            {(props) => {
              const { values, errors, handleChange, handleSubmit } =
                props
              return (
                <div className={profileInfoStyles.passwordSection}>
                  <div className={profileInfoStyles.formInfo}>
                    <form onSubmit={handleSubmit}>
                      <label htmlFor='newPassword'>Username</label>
                      <input
                        id='username'
                        type='text'
                        placeholder={user.username || 'Enter a username'}
                        value={values.username}
                        onChange={handleChange}

                      />
                      {errors.newPassword && (
                        <span className={profileInfoStyles.inputFeedback}>
                          {errors.username}
                        </span>
                      )}
                      <button type='submit'>Change Username</button>
                    </form>
                  </div>

                </div>
              )
            }}
          </Formik>

        </>
      )}
      {inFetch && (
        <PropagateLoader
          color='#ffffff'
          loading={inFetch}
          size={5}
          cssOverride={{
            display: 'block',
            textAlign: 'center',
            marginBottom: '2.5em',
            marginTop: '2.5em'
          }}
          speedMultiplier={1}
        />
      )}
    </div>
  )
}
