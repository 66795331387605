import axios from 'axios'
import { getUser, setUser } from './auth'

const SERVER = process.env.REACT_APP_API_URL

export const getProjects = async () => {
  const token = getUser().jwt ? getUser().jwt : ''

  // Try to get all payloads
  try {
    const options = {
      method: 'GET',
      url: `${SERVER}/projects/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const result = await axios(options)
    const response = result.data
    return response
  } catch (e) {
    console.warn('Error in project/getProjects()', e.message)
    throw e
  }
}

export const newProject = async project => {
  // Try to create new payload
  const token = getUser().jwt ? getUser().jwt : ''
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/projects/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        project
      }
    }
    const result = await axios(options)
    const response = result.data
    if (response.success) {
      const user = getUser()
      console.log('user', user)
      if (!user.projects || !Array.isArray(user.projects)) {
        user.projects = []
      }
      user.projects.push(response.project._id)
      setUser(user)
    }
    return response
  } catch (e) {
    console.warn('Error in project/newProject()', e.message)
    throw e
  }
}
// update project
export const updateProject = async project => {
  const token = getUser().jwt ? getUser().jwt : ''
  try {
    const options = {
      method: 'PUT',
      url: `${SERVER}/projects/${project._id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        project
      }
    }
    const result = await axios(options)
    const response = result.data

    return response
  } catch (e) {
    console.warn('Error in project/newProject()', e.message)
    throw e
  }
}

export const newSharableCollection = async collectionLabel => {
  // Try to create new payload
  const token = getUser().jwt ? getUser().jwt : ''
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/users/share/nft/create`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        sharableCollection: {
          collectionLabel
        }
      }
    }
    const result = await axios(options)
    const response = result.data

    // return the created sharable collection
    const user = response.user
    const sharable = user.nftSharedCollections.pop()
    console.log('new sharable', sharable)

    return sharable
  } catch (e) {
    console.warn('Error in project/newProject()', e.message)
    throw e
  }
}
export const getSharableCollections = async publicId => {
  // Try to create new payload
  const token = getUser().jwt ? getUser().jwt : ''
  try {
    const options = {
      method: 'GET',
      url: `${SERVER}/users/share/nft/collections/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const result = await axios(options)
    const response = result.data
    console.log('response', response)

    return response
  } catch (e) {
    console.warn('Error in project/newProject()', e.message)
    throw e
  }
}

export const getSharableTokens = async publicId => {
  // Try to create new payload
  const token = getUser().jwt ? getUser().jwt : ''
  try {
    const options = {
      method: 'GET',
      url: `${SERVER}/users/share/nft/tokens/${publicId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const result = await axios(options)
    const response = result.data
    // console.log('response', response)

    return response
  } catch (e) {
    console.warn('Error in project/newProject()', e.message)
    throw e
  }
}

export const exportSharableToken = async (tokenId, slpAddress, publicId) => {
  const user = getUser()
  const token = user.jwt

  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/token/export/sharable`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        tokenId,
        slpAddress,
        publicId
      }
    }
    const response = await axios(options)
    const resp = response.data
    // console.log('resp', resp)
    return resp.result
  } catch (e) {
    return false
  }
}
export const updateSharableCollection = async (sharableCollection) => {
  const user = getUser()
  const token = user.jwt

  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/users/share/nft/update`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        sharableCollection
      }
    }
    const response = await axios(options)
    const resp = response.data
    console.log('resp', resp)
    return resp.result
  } catch (e) {
    return false
  }
}
