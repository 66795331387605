import React, { useState } from 'react'

import hamburger from '../components/assets/hamburger.svg'
import chevron from '../components/assets/chevron-up.svg'
import SideMenuContent from './sideMenuContent'

import cSideMenuStyles from './styles/collectionsSideMenu.module.scss'

export default function CollectionsSideMenu (props) {
  const { collections, getTokens, section } = props
  const [outsideR, setOutsideR] = useState(true)
  const [outside, setOutside] = useState(true)
  const [toggle, setToggle] = useState(true)
  const onClick = () => setToggle(!toggle)
  const outsideClick = () => setOutside(!outside)
  const rotateOut = () => setOutsideR(!outsideR)
  return (
    <>
      <img
        className={cSideMenuStyles.hamburger}
        src={hamburger}
        alt='hamburger icon'
        onClick={onClick}
      />
      {toggle
        ? (
          <div className={cSideMenuStyles.container}>
            <div
              className={cSideMenuStyles.section}
              onClick={() => {
                outsideClick()
                rotateOut()
              }}
            >
              <h2>Collections</h2>{' '}
              <img
                className={
                outsideR
                  ? `${cSideMenuStyles.chevron}`
                  : `${cSideMenuStyles.chevronDown}`
              }
                src={chevron}
                alt='chevron icon'
              />
            </div>
            {outside
              ? (
                <>
                  {
                   collections && collections.map((val, i) => {
                     return <SideMenuContent key={`collection-${i}`} collection={val} getTokens={getTokens} section={section} />
                   })
                  }
                </>
                )
              : null}
          </div>
          )
        : null}
    </>
  )
}
