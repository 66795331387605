import React, { useState } from 'react'

// Assets
import burnStyles from './styles/burn.module.scss'
import xmark from './assets/xmark.svg'
import warn from './assets/warn.svg'
import check from './assets/check.svg'
import fire from './assets/fire.svg'

// Services
import { burnProjectToken } from '../services/token'
import { exportSharableToken } from '../services/project'

// Spinner
import { motion } from 'framer-motion'

// Notification lib
import { toast } from 'react-toastify'

// Redux Functions
import actions from '../redux/actions'

const TOKEN_EXPLORER = process.env.REACT_APP_TOKEN_EXPLORER

const { useDispatch, deleteCollectionToken } = actions

// Send a token to this address to burn it
const BURN_ADDRESS = 'simpleledger:qqsrke9lh257tqen99dkyy2emh4uty0vkyflye6lwa'

export default function Burn (props) {
  const { close, collection, token, selectedSection } = props

  const [txId, setTxId] = useState('')
  const [inFetch, setInFetch] = useState(false)

  const dispatch = useDispatch()

  // handle burn in personal collection
  const burnFromProject = async () => {
    try {
      // show spinner
      setInFetch(true)

      // export token
      const txId = await burnProjectToken(collection._id, token.tokenId)
      if (!txId) {
        throw new Error('Token cant be burned')
      }
      // delete token from redux store ( delete token from view )
      dispatch(deleteCollectionToken({ collectionId: collection._id, token }))

      setTxId(txId)
      setInFetch(false)
      toast.success('Success!')
    } catch (error) {
      console.warn('Error in burn/burnFromProject()', error)
      setInFetch(false)
      toast.error('Error! : ' + error.message)
    }
  }
  // handle burn in sharable collection
  const burnFromSharable = async () => {
    try {
      // show spinner
      setInFetch(true)

      // export token
      const txId = await exportSharableToken(
        token.tokenId,
        BURN_ADDRESS,
        collection.publicId
      )
      if (!txId) {
        throw new Error('Token cant be burned!')
      }

      // delete token from redux store ( delete token from view )
      const collectionId = collection.publicId
      dispatch(deleteCollectionToken({ collectionId, token }))

      setTxId(txId)
      setInFetch(false)
      toast.success('Success!')
    } catch (error) {
      console.warn('Error in burn/burnFromSharable()', error)
      setInFetch(false)
      toast.error('Error! : ' + error.message)
    }
  }

  const submit = async () => {
    if (selectedSection === 'collections') {
      await burnFromProject()
    } else if (selectedSection === 'sharable') {
      await burnFromSharable()
    }
  }

  return (
    <>
      <div className={burnStyles.container}>
        <div className={burnStyles.header}>
          <img
            className={burnStyles.xmark}
            src={xmark}
            alt='close btn'
            onKeyDown={close}
            onClick={close}
          />
          <h2>{!txId ? (!inFetch ? 'Burn NFT?' : 'Burning NFT') : ''}</h2>
        </div>
        {!inFetch && !txId && (
          <>
            <div className={burnStyles.title}>
              <p>
                Are you sure you want to burn <span>{token.name} </span>?
              </p>
              <p>You can't undo this action.</p>
            </div>
            <div className={burnStyles.warnSection}>
              <div className={burnStyles.warnHeader}>
                <img
                  src={warn}
                  alt='warn logo'
                  className={burnStyles.warnLogo}
                />
                <h4>Warning</h4>
              </div>
              <p>
                By burning this NFT you are taking it out of circulation forever.
              </p>
            </div>
            <div className={burnStyles.btnSection}>
              <button className={burnStyles.cancelBtn} onClick={close}>
                Cancel
              </button>
              <button className={burnStyles.burnBtn} onClick={submit}>Burn NFT</button>

            </div>
          </>
        )}
        {!inFetch && txId && (
          <div className={burnStyles.successContainer}>
            <img className={burnStyles.check} src={check} alt='success icon' />
            <h2>NFT burn successful</h2>
            <span>Check out your transaction on our block explorer:</span>
            <a
              href={`${TOKEN_EXPLORER}/transactions/?txid=${txId}`}
              target='_blank'
              without
              rel='noreferrer'
              className={burnStyles.txid}
            >
              TXID: <br /> {txId}
            </a>
          </div>
        )}

        {inFetch && (
          <motion.img
            dragSnapToOrigin='true'
            drag='x'
            dragTransition={{ bounceStiffness: 800, bounceDamping: 20 }}
            dragConstraints={{ left: 50, right: 50 }}
            animate={{
              scale: [1, 1.5, 1]
            }}
            transition={{
              repeat: Infinity,
              duration: 2
            }}
            className={burnStyles.fire}
            src={fire}
            alt='burning nft'
          />
        )}
      </div>
    </>
  )
}
