import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
  alreadyLoaded: false,
  selected: null,
  tokensByCollections: {}, // { key : <projectId> , value : <tokens> }
  sharables: [], // sharables collections
  shareablesAlreadyLoaded: false,
  selectedSharable: null
}

export const collectionSlice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    // add an array of collections
    addCollections: (state, action) => {
      state.value = action.payload
    },
    // add one collection to  collections array
    pushCollection: (state, action) => {
      const collections = state.value
      collections.push(action.payload)
      state.value = collections
    },
    // edit collection
    editCollection: (state, action) => {
      const collection = action.payload
      const indextoReplace = state.value.findIndex((val) => { return val._id === collection._id })
      state.value[indextoReplace] = collection
    },
    // define fetch-collection state
    setAlreadyLoaded: (state) => {
      state.alreadyLoaded = true
    },
    // define fetch-collection state
    setSelectedCollection: (state, action) => {
      state.selected = action.payload
    },
    // add tokens from a collection to the <tokensByCollections> object
    addCollectionTokens: (state, action) => {
      const key = action.payload.collectionId
      const value = action.payload.tokens

      const tokensByCollections = state.tokensByCollections
      tokensByCollections[key] = value

      state.tokensByCollections = tokensByCollections
    },

    // add tokens from a collection to the <tokensByCollections> object
    addTokenToCollection: (state, action) => {
      const key = action.payload.collectionId
      const value = action.payload.token

      const tokensByCollections = state.tokensByCollections
      const collectionTokens = tokensByCollections[key]
      if (!collectionTokens) return

      collectionTokens.push(value)
      state.tokensByCollections[key] = collectionTokens
    },
    // update a token data
    updateTokenData: (state, action) => {
      const key = action.payload.collectionId
      const value = action.payload.token

      const tokensByCollections = state.tokensByCollections
      const collectionTokens = tokensByCollections[key]
      if (!collectionTokens) return

      const indextoReplace = collectionTokens.findIndex((val) => { return val.tokenId === value.tokenId })
      collectionTokens[indextoReplace] = value

      state.tokensByCollections[key] = collectionTokens
    },
    /** Removes the token from the <tokensByCollections> , <value> states */
    deleteCollectionToken: (state, action) => {
      const key = action.payload.collectionId // collection id
      const value = action.payload.token // token

      const tokensByCollections = state.tokensByCollections
      const collectionTokens = tokensByCollections[key]
      if (!collectionTokens) return

      // delete token from <tokensByCollections>
      const indexToDeleteFromObject = collectionTokens.findIndex((val) => { return val.tokenId === value.tokenId }) // find index to delete
      collectionTokens.splice(indexToDeleteFromObject, 1) // splice array to delete token from tokens array
      state.tokensByCollections[key] = collectionTokens // update state

      // delete token from collection.tokens into <value> state
      const selectedCollectionIndex = state.value.findIndex((val) => { return val._id === key }) // find collection index
      const collection = state.value[selectedCollectionIndex] // get collection from object
      if (!collection) return
      const indexToDeleteFromCollection = collection.tokens.findIndex((val) => { return val === value.tokenId }) // find index to delete
      collection.tokens.splice(indexToDeleteFromCollection, 1) // splice tokens array
      state.value[selectedCollectionIndex] = collection // update state
    },

    // add an array of sharables collections
    addSharables: (state, action) => {
      state.sharables = action.payload
    },
    // add one collection to  shrable collections array
    pushSharable: (state, action) => {
      const sharablesCollections = state.sharables
      sharablesCollections.push(action.payload)
      state.sharables = sharablesCollections
    },
    // edit shrable collection
    editSharable: (state, action) => {
      const sharableCollection = action.payload
      const indextoReplace = state.sharables.findIndex((val) => { return val.publicId === sharableCollection.publicId })
      state.sharables[indextoReplace] = sharableCollection
    },
    // define fetch-sharables-collection state
    setShrablesAlreadyLoaded: (state) => {
      state.shareablesAlreadyLoaded = true
    },
    // define fetch-collection state
    setSelectedSharableCollection: (state, action) => {
      state.selectedSharable = action.payload
    },
    resetCollections: () => {
      return initialState
    }
  }
})

// Action creators are generated for each case reducer function
export const {
  setDefault,
  addCollections,
  pushCollection,
  setAlreadyLoaded,
  setSelectedCollection,
  addCollectionTokens,
  addTokenToCollection,
  updateTokenData,
  deleteCollectionToken,
  resetCollections,
  editCollection,
  addSharables,
  pushSharable,
  editSharable,
  setShrablesAlreadyLoaded,
  setSelectedSharableCollection
} = collectionSlice.actions

export default collectionSlice.reducer
