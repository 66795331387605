/** Redux Store in order to save the credit data  */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {
    available: 0
  }
}

export const creditSlice = createSlice({
  name: 'credit',
  initialState,
  reducers: {
    updateCreditData: (state, action) => {
      state.value = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { updateCreditData } = creditSlice.actions

export default creditSlice.reducer
