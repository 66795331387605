import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons'
import { logout, getAppMode, setAppMode } from '../services/auth'
import Popup from 'reactjs-popup'

import user from './assets/user.svg'
import power from './assets/power.svg'
import ProfileInfo from './profileInfo'
import credits from './assets/credits.svg'
import profileStyles from './styles/profile.module.scss'
import actions from '../redux/actions'
import CreditsModal from './creditsModal'
import { fetchUserData } from '../services/user'

const { useDispatch, resetCollections, resetCreateFormValues, useSelector, updateCreditData } = actions
export default function Profile () {
  const myCredits = useSelector((state) => state.credit.value)

  const [creditAvailable, setCreditAvailable] = useState(0)
  const [isDarkMode, setIsDarkMode] = useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ref = useRef() // profile ref
  const creditRef = useRef() // creditRef

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode)
  }

  // Set the last mode stored in cache
  useEffect(() => {
    const appMode = getAppMode()
    if (!appMode || appMode === 'Dark') {
      setAppMode('Dark')
      setIsDarkMode(true)
    } else if (appMode === 'Light') {
      setIsDarkMode(false)
    }
  }, [])

  // Get credits and update redux store
  useEffect(() => {
    const fetch = async () => {
      const _user = await fetchUserData()
      dispatch(updateCreditData(_user.credit))
    }

    fetch()
  }, [dispatch])

  useEffect(() => {
    setCreditAvailable(myCredits.available)
  }, [myCredits])

  const closeTooltip = useCallback(() => {
    ref.current.close()
  }, [ref])

  const closeCreditTooltip = useCallback(() => {
    creditRef.current.close()
  }, [creditRef])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.style.setProperty(
        '--primary-color',
        'rgb(0, 0, 0)'
      )
      document.documentElement.style.setProperty('--secondary-color', '#fff')
      document.documentElement.style.setProperty(
        '--tertiary-color',
        '#3b9f6f34'
      )
      document.documentElement.style.setProperty(
        '--quaternary-color',
        'rgba(255, 255, 255, 0.4)'
      )
      setAppMode('Dark')
    } else {
      document.documentElement.style.setProperty(
        '--primary-color',
        'rgb(247, 244, 244)'
      )
      document.documentElement.style.setProperty(
        '--secondary-color',
        'rgb(26, 24, 24)'
      )
      document.documentElement.style.setProperty(
        '--tertiary-color',
        '#3b9f6fe7'
      )
      document.documentElement.style.setProperty('--quaternary-color', '#333')
      setAppMode('Light')
    }
  }, [isDarkMode])

  const Logout = () => {
    // reset old redux  state from store
    dispatch(resetCollections())
    dispatch(resetCreateFormValues())

    logout(navigate('/login'))
  }

  return (
    <div className={profileStyles.container}>
      <Popup
        ref={creditRef}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        trigger={
          <div className={profileStyles.credits}>
            <img
              className={profileStyles.creditLogo}
              src={credits}
              alt='credits logo'
            />
            <span>{creditAvailable} Credits</span>
          </div>
        }
        p
        modal
      >
        <span>
          <CreditsModal close={closeCreditTooltip} />
        </span>
      </Popup>
      {/* <div className={profileStyles.proBtn}>
        <span>Pro mode:</span>
        <div className={profileStyles.toggleBtn}>
          <input disabled type='checkbox' id='switch2' />
          <label htmlFor='switch2'>Toggle</label>
        </div>
      </div> */}
      <Popup
        ref={ref}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        trigger={
          <img className={profileStyles.user} src={user} alt='user icon' />
        }
        p
        modal
      >
        <span>
          <ProfileInfo close={closeTooltip} />
        </span>
      </Popup>
      <img
        className={profileStyles.power}
        src={power}
        alt='logout icon'
        onClick={Logout}
      />
      <p onClick={toggleDarkMode}>
        <FontAwesomeIcon
          className={profileStyles.themeBtn}
          icon={isDarkMode ? faSun : faMoon}
        />
      </p>
    </div>
  )
}
