import React, { useState } from 'react'
import Typewriter from 'typewriter-effect'
import studioStyles from './styles/studio.module.scss'
import { generateImage, getFile } from '../services/openai'
import { useNavigate } from 'react-router-dom'
import PropagateLoader from 'react-spinners/PropagateLoader'
import { getUser } from '../services/auth'

// Redux Functions
import actions from '../redux/actions'
const { useSelector, useDispatch, addCreateFormValues } = actions

export default function Studio () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [prompt, setPrompt] = useState('')
  const [inFetch, setInFetch] = useState(false)
  const [imgUrls, setImgUrls] = useState([])
  const [imgLoadedCount, setImgLoadedCount] = useState(0)
  const [imgsLoaded, setImgsLoaded] = useState(false)
  const [selectedImg, setSelectedImg] = useState(null)

  const formPreviousState = useSelector((state) => state.createForm.value)

  const handlePromptChange = (e) => {
    setPrompt(e.target.value)
  }

  // Submit prompt request
  const submit = async () => {
    if (inFetch) return
    resetValues()
    setInFetch(true)
    const result = await generateImage({ prompt, n: 4 })
    setInFetch(false)
    setImgUrls(result)
  }

  // Redirect to /create page
  const submitCreate = async () => {
    setInFetch(true)
    const blob = await getFile(selectedImg)

    const urlCreator = window.URL || window.webkitURL
    const imageUrl = urlCreator.createObjectURL(blob)
    const user = getUser()
    const email = user.email

    // get the name of the email without the domain
    const indexToSplit = email.indexOf('@')
    const userNameByEmail = email.slice(0, indexToSplit)

    const userName = userNameByEmail
    const fileData = {
      type: blob.type,
      name: `${userName}-studio`,
      url: imageUrl,
      size: blob.size
    }
    const formData = Object.assign({}, formPreviousState)
    formData.previousFile = fileData

    dispatch(addCreateFormValues(formData))

    navigate('/create')
  }
  // Catch 'Enter' key on the input text
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      submit()
    }
  }

  const onLoadImg = () => {
    const imgLoaded = imgLoadedCount + 1
    setImgLoadedCount(imgLoaded)

    if (imgLoaded === imgUrls.length) {
      setImgsLoaded(true)
    }
  }

  const resetValues = () => {
    setSelectedImg(false)
    setImgLoadedCount(0)
    setImgsLoaded(false)
    setImgUrls([])
  }

  return (
    <div className={studioStyles.container}>
      {/* Prompt section */}
      <h1>Enter a detailed description</h1>
      <div className={studioStyles.inputArea}>
        <textarea
          name='prompt'
          id='prompt'
          cols='30'
          rows='10'
          onChange={handlePromptChange}
          onKeyDown={handleKeyDown}

        />
        <button className={studioStyles.generateBtn} onClick={submit}>
          Generate
        </button>
      </div>
      {/* Generated results section */}
      {!inFetch && imgUrls.length > 0 && (
        <>
          <div className={studioStyles.titleBar}>
            <span>Results</span> <div className={studioStyles.bar} />
          </div>

          <div className={studioStyles.generatedImgContainer}>
            {imgUrls.map((val, i) => {
              return (
                <div key={`openai-img-${i}`}>
                  <div className={studioStyles.generatedImgSection}>
                    <img className={selectedImg === val.url ? studioStyles.selectedPicture : studioStyles.picture} src={val.url} alt='generated art' onLoad={onLoadImg} onClick={() => setSelectedImg(val.url)} />
                  </div>
                </div>
              )
            })}
          </div>
          {imgsLoaded && <button disabled={!selectedImg} className={studioStyles.createNftBtn} onClick={submitCreate}>Create NFT</button>}
          {/** render image loader  */}
          {!imgsLoaded && (
            <PropagateLoader
              color='#ffffff'
              loading={!imgsLoaded}
              size={5}
              cssOverride={{
                display: 'block',
                textAlign: 'center',
                marginBottom: '2.5em',
                marginTop: '3rem'
              }}
              speedMultiplier={1}
            />
          )}
        </>
      )}
      {selectedImg && <span />}
      {/** fetching img data loader */}
      {inFetch && (
        <span className={studioStyles.loadingText}><Typewriter
          options={{
            strings: ['We are generating your images', 'Please wait...'],
            autoStart: true,
            loop: true,
            deleteSpeed: 3,
            delay: 70
          }}
                                                   />
        </span>
      )}

    </div>
  )
}
