import { useSelector, useDispatch } from 'react-redux'
import {
  addCollections,
  pushCollection,
  setAlreadyLoaded,
  setSelectedCollection,
  addCollectionTokens,
  addTokenToCollection,
  updateTokenData,
  deleteCollectionToken,
  resetCollections,
  editCollection,
  addSharables,
  editSharable,
  pushSharable,
  setShrablesAlreadyLoaded,
  setSelectedSharableCollection
} from '../redux/reducers/collections'

import {
  addCreateFormValues,
  resetCreateFormValues,
  uppyStarted,
  updateUsername
} from '../redux/reducers/create-form'

import {
  addDataToGallery
} from '../redux/reducers/gallery'

import {
  updateCreditData
} from '../redux/reducers/credits'

const actions = {
  useSelector,
  useDispatch,
  addCollections,
  pushCollection,
  setAlreadyLoaded,
  setSelectedCollection,
  addCollectionTokens,
  addTokenToCollection,
  updateTokenData,
  deleteCollectionToken,
  resetCollections,
  editCollection,
  addCreateFormValues,
  resetCreateFormValues,
  uppyStarted,
  addSharables,
  editSharable,
  pushSharable,
  setShrablesAlreadyLoaded,
  setSelectedSharableCollection,
  addDataToGallery,
  updateUsername,
  updateCreditData
}

export default actions
