import React from 'react'

import xmark from '../components/assets/xmark.svg'
// import nodes from '../components/assets/share-nodes.svg'
// import link from '../components/assets/link.svg'
// import facebook from '../components/assets/facebook.svg'
// import twitter from '../components/assets/twitter.svg'
import createdStyles from './styles/created.module.scss'
import { useNavigate } from 'react-router-dom'

import actions from '../redux/actions'

const TOKEN_EXPLORER = process.env.REACT_APP_TOKEN_EXPLORER

const { useDispatch, useSelector, setSelectedCollection } = actions

export default function Created (props) {
  const { onClose, txId, nftData } = props
  const myCollections = useSelector((state) => state.collections.value)

  const navigate = useNavigate()

  // redux , collections handler
  const dispatch = useDispatch()

  // Go to collection page , and set the selected collection to load
  const navigateToCollections = () => {
    try {
      const collectionId = nftData.collectionId
      const collection = myCollections.find((val) => { return val._id === collectionId })
      dispatch(setSelectedCollection(collection))

      setTimeout(() => {
        navigate('/collections')
      }, 300)
    } catch (error) {
      console.warn(error)
    }
  }
  return (
    <div className={createdStyles.container}>
      <img
        className={createdStyles.close}
        src={xmark}
        alt='close btn'
        onClick={onClose}
      />
      <h2 className={createdStyles.title}>
        Congrats! you created:{' '}
        {nftData.name ? <span onClick={navigateToCollections}>{nftData.name}</span> : <span>...</span>}
      </h2>
      {nftData.preview && (
        <img
          onClick={navigateToCollections}
          className={createdStyles.nft}
          src={nftData.preview}
          alt='nft created'
        />
      )}
      {txId && (
        <div className={createdStyles.txidSection}>
          <span>Check out your NFT on our block explorer:</span>
          <a
            href={`${TOKEN_EXPLORER}/?tokenid=${txId}`}
            target='_blank'
            without
            rel='noreferrer'
          >
            TXID: {txId}
          </a>
        </div>
      )}

      {/* <div className={createdStyles.linksContainer}>
        <img className={createdStyles.link} src={nodes} alt='share' />
        <img className={createdStyles.link} src={link} alt='share' />
        <img className={createdStyles.link} src={facebook} alt='share' />
        <img className={createdStyles.link} src={twitter} alt='share' />
      </div> */}
    </div>
  )
}
