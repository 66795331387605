import axios from 'axios'
import { getUser } from './auth'

const SERVER = process.env.REACT_APP_API_URL
const GATEWAY = process.env.REACT_APP_IPFS_GATEWAY

export const tokenizePayload = async (payloadId, customData) => {
  // Try to create new payload token
  const token = getUser().jwt ? getUser().jwt : ''
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/token/tokenize-payload/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        payloadId,
        customData
      }
    }
    const result = await axios(options)
    const response = result.data
    console.log('response', response)
    return response
  } catch (e) {
    console.warn('Error in token/tokenizePayload()', e.message)
    throw e
  }
}

export const getProjectTokens = async projectId => {
  const token = getUser().jwt ? getUser().jwt : ''

  // Try to get  payload by id
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/token/project-tokens`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        projectId
      }
    }
    const result = await axios(options)
    const response = result.data
    console.log('response', response)
    return response
  } catch (e) {
    console.warn('Error in token/getProjectTokens()', e.message)
    throw e
  }
}

// Get cid from  provided URL. And convert the url to psffpp gateway format ( /ipfs/view/<cid> )
const convertToPsffppGateway = (iconURL) => {
  try {
    if (!iconURL) return iconURL
    const index = iconURL.lastIndexOf('/')

    const cid = iconURL.substring(index + 1, iconURL.length)
    return `/ipfs/view/${cid}`
  } catch (error) {
    console.warn(error)
  }
}

// get  token icon
export const getTokenIcon = async (tokenId) => {
  const token = getUser().jwt ? getUser().jwt : ''

  // try to get token icon
  try {
    const options = {
      method: 'GET',
      url: `${SERVER}/token/icon/${tokenId}`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const result = await axios(options)
    const res = result.data
    console.log('res', res)
    const url = `${GATEWAY}${convertToPsffppGateway(res.result)}`
    return url
  } catch (e) {
    return false
  }
}

// get  token data
export const getTokenData = async (tokenIds) => {
  const token = getUser().jwt ? getUser().jwt : ''

  // try to get token data
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/token/data/`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        tokenIds
      }
    }
    const result = await axios(options)
    const res = result.data
    return res
  } catch (e) {
    return false
  }
}
// Export project token
export const exportProjectToken = async (tokenId, slpAddress, projectId) => {
  const token = getUser().jwt ? getUser().jwt : ''
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/token/export/project`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        tokenId,
        slpAddress,
        projectId
      }
    }
    const data = await axios(options)
    const txids = data.data
    return txids.result
  } catch (e) {
    return false
  }
}

export const burnProjectToken = async (projectId, tokenId) => {
  const token = getUser().jwt ? getUser().jwt : ''

  // try to get all projects
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/token/burn/project`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        projectId,
        tokenId
      }
    }
    const data = await axios(options)
    const txids = data.data
    console.log('txids', txids)
    return txids.result
  } catch (e) {
    return false
  }
}
