import React, { useState, useEffect } from 'react'

import userWalletDataStyles from './styles/userWalletData.module.scss'

import copy from './assets/copy.svg'

// Services
import { getUser, setUser } from '../services/auth'
import { getWalletAddresses } from '../services/user'

// Spinner
import PropagateLoader from 'react-spinners/PropagateLoader'

import { toast } from 'react-toastify'

export default function UserWalletData (props) {
  const { close } = props
  const [inFetch, setInFetch] = useState('')
  const [email, setEmail] = useState('')
  const [addresses, setAddresses] = useState({})

  useEffect(() => {
    // The addresses should be loaded at login
    // If you had an active session before this update
    // it can throw an error, to solve that, this functionality was created
    // if the active session does not have their addresses, then a request will be made to look them up.
    const fetchWalletAddresses = async () => {
      setInFetch(true)
      const userAddresses = await getWalletAddresses()

      const user = getUser()
      const updatedUser = Object.assign({}, user)
      updatedUser.addresses = userAddresses.addresses
      setUser(updatedUser)
      setEmail(user.email)
      setAddresses(updatedUser.addresses)
      setInFetch(false)
    }

    const user = getUser()

    if (user.addresses) {
      setEmail(user.email)
      setAddresses(user.addresses)
    } else {
      fetchWalletAddresses()
    }
  }, [])
  // Copy sharable link to clipboard
  const copyToClipboard = () => {
    window.navigator.clipboard.writeText(addresses.slpAddress)
    toast.success('Copied to clipboard!')
    close()
  }
  return (
    <div className={userWalletDataStyles.container}>
      <h3><strong>{email}  </strong> Wallet Address</h3>

      {addresses && addresses.slpAddress && (
        <>
          <p>Copy the address below and share it with friends! <br /> They will be able to send you NFTs to this address</p>
          <hr />
          <span className={userWalletDataStyles.link}>
            {addresses.slpAddress}
          </span>
          <img className={userWalletDataStyles.copyBtn} src={copy} alt='copy button' onClick={copyToClipboard} />
          <hr />
        </>
      )}
      {inFetch && (
        <PropagateLoader
          color='rgb(59, 159, 111)'
          loading={inFetch}
          size={7}
          cssOverride={{
            display: 'block',
            textAlign: 'center',
            marginBottom: '2.5em',
            marginTop: '2.5em'
          }}
          speedMultiplier={1}
          style={{ position: 'absolute', top: '60%', left: '50%' }}
        />
      )}

      <button
        className={userWalletDataStyles.closeBtn}
        onKeyDown={close}
        onClick={close}
      >
        Close
      </button>
    </div>
  )
}
