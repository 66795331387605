import React, { useState, useEffect } from 'react'

import sharableStyles from './styles/sharableLink.module.scss'

import copy from './assets/copy.svg'
import { getUser } from '../services/auth'
import { toast } from 'react-toastify'

const SPA_URL = process.env.REACT_APP_SPA_URL

export default function SharableLink (props) {
  const { close, collection } = props
  const [sharableLink, setSharableLink] = useState(null)
  useEffect(() => {
    if (collection.publicId) {
      getSharableLink(collection)
    }
  }, [collection])

  // Generate sharable link
  // Sharable link example   https://tokentiger.com/users/share/nft/63c9918e0b1297300984bf7f/12345678901234567890123456789012
  const getSharableLink = (collection) => {
    try {
      const user = getUser()
      const publicId = collection.publicId
      const link = `${SPA_URL}/users/share/nft/${user._id}/${publicId}`
      setSharableLink(link)
      return link
    } catch (error) {
      console.warn('Error in getSharableLink()', error)
    }
  }
  // Copy sharable link to clipboard
  const copyToClipboard = () => {
    window.navigator.clipboard.writeText(sharableLink)
    toast.success('Copied to clipboard!')
    close()
  }
  return (
    <div className={sharableStyles.container}>
      <h3>Sharable Collection <strong>{collection.collectionLabel}</strong></h3>
      <p>Copy the link below to share this collection with friends!</p>
      <hr />
      {sharableLink && (
        <span className={sharableStyles.link}>
          <a href={sharableLink} target='_blank' rel='noreferrer'>{sharableLink}</a>
        </span>
      )}
      <img className={sharableStyles.copyBtn} src={copy} alt='copy button' onClick={copyToClipboard} />
      <hr />
      <button
        className={sharableStyles.closeBtn}
        onKeyDown={close}
        onClick={close}
      >
        Close
      </button>
    </div>
  )
}
