/** QR scanner  */

import React from 'react'
import QrReader from 'react-qr-scanner'

import qrStyles from './styles/qrScanner.module.scss'

export default function QrScanner (props) {
  const { onError, onScan } = props

  const handleError = (err) => {
    console.error(err)
    onError ? onError(err) : console.error(err)
  }

  const handleScan = (data) => {
    if (data && data.text) {
      onScan && onScan(data.text)
    }
  }

  return (
    <div className={qrStyles.container}>
      <div>
        <QrReader
          className={qrStyles.qrSize}
          delay={500}
          onError={handleError}
          onScan={handleScan}
        />
      </div>
    </div>
  )
}
