/**
 *
 * COLLECTION VIEW FLOW
 *
 * -when you click for the first time on the tokens side-menu, it will bring up all the tokens of a collection,
 * While these data are obtained, the icons of the tokens will be displayed on their respective cards.
 *
 * -Once the tokens of a collection are obtained, these will be stored in redux, this way is avoided to call
 * the api again, once the user returns to this view.
 *
 * -When adding a new token in a collection that already has the tokens previously loaded,
 * only the new token will be fetched and added to redux.
 *
 *  TODO:
 *
 * -When burning or sending a token, it should be removed from the redux-store , and should refresh the view
 * with existing tokens.
 *
 *
 */

import React, { useEffect, useState, useRef } from 'react'
import Popup from 'reactjs-popup'

// Components
import CollectionsSideMenu from '../components/collectionsSideMenu'
import CollectionsTopMenu from '../components/collectionsTopMenu'
import TokensCard from '../components/tokensCard'
import IconsMenu from '../components/iconsMenu'
import SharableLink from '../components/sharableLink'
import UserWalletData from '../components/userWalletData'
import SharableCollectionData from '../components/sharableCollectionData'

// styles
import collectionStyles from './styles/collections.module.scss'

// Services
import { getUser } from '../services/auth'
import { getProjects, getSharableCollections, getSharableTokens } from '../services/project'
import { getProjectTokens, getTokenData } from '../services/token'
import { getUserTokens } from '../services/user'

// Redux Functions
import actions from '../redux/actions'

// Spinner
import PropagateLoader from 'react-spinners/PropagateLoader'

const {
  useSelector,
  useDispatch,
  addCollections,
  setAlreadyLoaded,
  addCollectionTokens,
  addSharables,
  setShrablesAlreadyLoaded,
  editSharable
} = actions

export default function Collections () {
  const [selectedSection, setSelectedSection] = useState('collections')
  const [selectedTokens, setSelectedTokens] = useState(null) // tokens of the selected collection
  const [selectedSharableTokens, setSelectedSharableTokens] = useState(null) // tokens of the selected collection
  const [selectedUserTokens, setSelectedUserTokens] = useState(null) // User Wallet Tokens

  const [fetchTokens, setFetchTokens] = useState(false) // flag to know if there is already a request in progress to obtain the tokens
  const ref = useRef()
  const selectedCollectionRef = useRef()
  const closeTooltip = () => {
    ref.current.close()
  }
  // redux , collections handler
  const dispatch = useDispatch()
  const myCollections = useSelector((state) => state.collections.value)
  const mySharableCollections = useSelector((state) => state.collections.sharables)
  const collectionsTokens = useSelector(
    (state) => state.collections.tokensByCollections
  )
  const selectedCollection = useSelector((state) => state.collections.selected)
  const selectedSharableCollection = useSelector((state) => state.collections.selectedSharable)

  const collectionsIsLoaded = useSelector(
    (state) => state.collections.alreadyLoaded
  )
  const sharablesCollectionsIsLoaded = useSelector(
    (state) => state.collections.shareablesAlreadyLoaded
  )

  // update tokens view
  const updateSelectedTokens = () => {
    if (selectedSection === 'collections') {
      if (selectedCollection && selectedCollection._id) {
        setSelectedTokens(collectionsTokens[selectedCollection._id])
      }
    } else if (selectedSection === 'user-wallet') {
      setSelectedUserTokens(collectionsTokens['user-wallet-tokens'])
    } else if (selectedSection === 'sharable') {
      setSelectedSharableTokens(collectionsTokens[selectedSharableCollection.publicId])
    }
  }
  useEffect(() => {
    selectedCollectionRef.current = selectedCollection
  }, [selectedCollection])
  // This Hook acts as a component did mount
  useEffect(() => {
    // Get collections if it has not been fetched
    const getCollections = async () => {
      await getUserCollections()
    }
    const getSharablesCollections = async () => {
      await getUserSharableCollections()
    }
    if (!collectionsIsLoaded) {
      getCollections()
    }
    if (!sharablesCollectionsIsLoaded) {
      getSharablesCollections()
    }
    // eslint-disable-next-line
  }, [collectionsIsLoaded, sharablesCollectionsIsLoaded]);

  // This hook reacts to a selected collection
  // when this selected collection changes, load its tokens
  useEffect(() => {
    if (selectedCollection && selectedCollection._id) {
      getTokens(selectedCollection)
    }
    // eslint-disable-next-line
  }, [selectedCollection]);

  // This hook reacts to a selected sharable collection
  // when this selected collection changes, load its tokens
  useEffect(() => {
    if (selectedSharableCollection && selectedSharableCollection.publicId) {
      getSharableCollectionTokens(selectedSharableCollection)
    }
    // eslint-disable-next-line
  }, [selectedSharableCollection])

  useEffect(() => {
    if (selectedCollection && selectedCollection._id) {
      getTokens(selectedCollection)
    }
    if (selectedSharableCollection && selectedSharableCollection.publicId) {
      getSharableCollectionTokens(selectedSharableCollection)
    }
    if (selectedSection === 'user-wallet') {
      getUserWalletTokens()
    }
    // eslint-disable-next-line
  }, [selectedSection]);

  // get user collections  ( projects )
  const getUserCollections = async () => {
    try {
      const collectionsResult = await getProjects()
      const userData = getUser() // Get my user from storage

      const collections = [] // Users projects (collections) array
      // Get only user associated projects / collections
      if (!userData || !userData.projects) return []
      collectionsResult.projects.map((val) => {
        userData.projects.map((value) => {
          if (value === val._id) collections.push(val)
          return true
        })
        return true
      })
      // Add collections in redux store
      dispatch(addCollections(collections))
      dispatch(setAlreadyLoaded())

      return collections
    } catch (error) {
      console.error('Error in getUserCollections', error)
    }
  }

  // get user sharable collections
  const getUserSharableCollections = async () => {
    try {
      const collectionsResult = await getSharableCollections()
      const sharablesC = collectionsResult.nftSharedCollections

      // Add sharable collections in redux store
      dispatch(addSharables(sharablesC))
      dispatch(setShrablesAlreadyLoaded())

      return sharablesC
    } catch (error) {
      console.error('Error in getUserSharableCollections', error)
    }
  }

  // get the tokens given a collection id
  // if the tokens of this collection have already been previously loaded to redux
  // will avoid calling the api to search for them.
  const getTokens = async (collection) => {
    try {
      // Return if is already in fetch
      if (fetchTokens) {
        return
      }

      if (collection.tokens && !collection.tokens.length) {
        setSelectedTokens([])
        return
      }

      console.log(`Get ${collection.projectLabel} tokens`)

      const collectionId = collection._id
      // get the tokens of the selected collection from redux
      const collectionTokens = collectionsTokens[collectionId]

      // if the selected collection does not yet have the
      // loaded tokens in redux , make an api request to get them
      if (!collectionTokens) {
        setFetchTokens(true)

        // load the card of the tokens, with the image of the token
        // while getting the full data of the tokens
        const currentData = getTokensCurrentData(collection)
        setSelectedTokens(currentData)

        // get tokens for the selected collection
        const resp = await getProjectTokens(collectionId)
        // add tokens to redux store
        dispatch(addCollectionTokens({ collectionId, tokens: resp.tokens }))
        setSelectedTokens(resp.tokens)
      } else {
        setSelectedTokens(collectionTokens)
      }
      setFetchTokens(false)
    } catch (error) {
      console.error('Error in getTokens', error)
    }
  }

  // get  tokens from a sharable collection
  const getSharableCollectionTokens = async (collection) => {
    try {
      // Return if is already in fetch
      if (fetchTokens) {
        return
      }

      console.log(`Get ${collection.collectionLabel} tokens`)

      const collectionId = collection.publicId

      // get the tokens of the selected collection from redux
      const collectionTokens = collectionsTokens[collectionId]

      if (!collectionTokens) {
        setFetchTokens(true)

        // get sharable tokens
        const result = await getSharableTokens(collection.publicId)
        const tokens = result.tokens
        const sharableCollection = Object.assign({}, collection)
        sharableCollection.tokens = tokens

        // set spinner to false in order to show card with progressive loading
        setFetchTokens(false)

        dispatch(editSharable(sharableCollection))

        // load the card of the tokens, with the image of the token
        // while getting the full data of the tokens
        const currentData = getTokensCurrentData(sharableCollection)
        setSelectedSharableTokens(currentData)

        // array of token id
        const tokenIdArr = []
        for (let i = 0; i < tokens.length; i++) {
          const tokenId = tokens[i].tokenId
          tokenIdArr.push(tokenId)
        }

        // get tokens data
        const tokenData = await getTokenData(tokenIdArr)
        const sharableTokensResult = tokenData.result
        const sharableTokens = []

        // get genesis data from token data
        for (let i = 0; i < sharableTokensResult.length; i++) {
          const token = sharableTokensResult[i].genesisData
          sharableTokens.push(token)
        }
        // set collections into redux
        dispatch(addCollectionTokens({ collectionId, tokens: sharableTokens }))

        setSelectedSharableTokens(sharableTokens)
      } else {
        setSelectedSharableTokens(collectionTokens)

        setFetchTokens(false)
      }
    } catch (error) {
      console.error('Error in getSharableTokens', error)
    }
  }

  // get  user wallet tokens
  const getUserWalletTokens = async () => {
    try {
      // Return if is already in fetch
      if (fetchTokens) {
        return
      }
      const userData = getUser()
      console.log(`Get ${userData.email} tokens`)

      const collectionId = 'user-wallet-tokens'

      // get the tokens from the redux store
      // User wallet tokens are set to redux store as a collection
      const userWalletTokensTokens = collectionsTokens[collectionId]

      if (!userWalletTokensTokens) {
        setFetchTokens(true)

        // get tokens
        const result = await getUserTokens()
        console.log('result', result)

        const tokens = result.tokens
        // set spinner to false in order to show card with progressive loading
        setFetchTokens(false)

        // load the card of the tokens, with the image of the token
        // while getting the full data of the tokens
        const currentData = getTokensCurrentData({ tokens })
        setSelectedUserTokens(currentData)

        // array of token id
        const tokenIdArr = []
        for (let i = 0; i < tokens.length; i++) {
          const tokenId = tokens[i].tokenId
          tokenIdArr.push(tokenId)
        }
        // get tokens data
        const tokenData = await getTokenData(tokenIdArr)
        const userWalletsTokensResult = tokenData.result
        const userWalletTokens = []
        // get genesis data from token data
        for (let i = 0; i < userWalletsTokensResult.length; i++) {
          const token = userWalletsTokensResult[i].genesisData
          userWalletTokens.push(token)
        }
        // set collections into redux
        dispatch(addCollectionTokens({ collectionId, tokens: userWalletTokens }))
        setSelectedUserTokens(userWalletTokens)
      } else {
        setSelectedUserTokens(userWalletTokensTokens)

        setFetchTokens(false)
      }
    } catch (error) {
      console.error('Error in getUserWalletTokens', error)
    }
  }
  // add the id tokens from the collection to an object
  const getTokensCurrentData = (collection) => {
    try {
      const { tokens } = collection
      const tokensMock = []
      for (let i = 0; i < tokens.length; i++) {
        const tokenId = tokens[i].tokenId ? tokens[i].tokenId : tokens[i]
        const token = {
          tokenId,
          localStatus: 'loading' // means that the token has not been fully loaded yet
        }
        tokensMock.push(token)
      }
      return tokensMock
    } catch (error) {
      console.error('Error in collections/getTokensCurrentData', error)
    }
  }

  return (
    <div className={collectionStyles.container}>
      <CollectionsTopMenu onChangeSection={setSelectedSection} />
      {selectedSection === 'sharable' &&
        (<section className={collectionStyles.publicTag}>Sharable NFT's</section>)}
      {selectedSection === 'collections' &&
        (<section className={collectionStyles.privateTag}>Personal NFT's</section>)}

      {selectedSection === 'user-wallet' &&
        (<section className={collectionStyles.userTag}>User NFT's</section>)}

      {selectedSection === 'sharable' && selectedSharableCollection && (
        <>
          <Popup
            ref={ref}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            trigger={<button className={collectionStyles.shareBtn}>Share this collection!</button>}
            p
            modal
          >
            <span>
              <SharableLink close={closeTooltip} collection={selectedSharableCollection} />
            </span>
          </Popup>
          <Popup
            ref={ref}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            trigger={<button className={collectionStyles.sharableAddrBtn}>Receive NFTs!</button>}
            p
            modal
          >
            <span>
              <SharableCollectionData close={closeTooltip} collection={selectedSharableCollection} />
            </span>
          </Popup>
        </>

      )}

      {selectedSection === 'user-wallet' && (
        <Popup
          ref={ref}
          closeOnDocumentClick={false}
          closeOnEscape={false}
          trigger={<button className={collectionStyles.addressBtn}>Receive NFTs!</button>}
          p
          modal
        >
          <span>
            <UserWalletData close={closeTooltip} />
          </span>
        </Popup>
      )}

      <div className={collectionStyles.wrap}>
        {
          selectedSection !== 'user-wallet' && (
            <div className={collectionStyles.menuWrap}>
              <CollectionsSideMenu
                collections={selectedSection === 'collections' ? myCollections : mySharableCollections}
                getTokens={selectedSection === 'collections' ? getTokens : getSharableTokens}
                section={selectedSection}
              />
            </div>
          )
        }
        {/** Private tokens , show mock cards  for progressive fetch */}
        {selectedSection === 'collections' &&
          (
            <div className={collectionStyles.tokensGrid}>
              {selectedTokens &&
                selectedTokens.map((val) => {
                  return (
                    <TokensCard
                      key={`card-${val.tokenId}`}
                      token={val}
                      collection={selectedCollection}
                      updateSelectedTokens={updateSelectedTokens}
                      selectedSection={selectedSection}
                    />
                  )
                })}
            </div>
          )}
        {/** Public tokens show spinner while tokens are fetched */}
        {selectedSection === 'sharable' && !fetchTokens &&
          (
            <div className={collectionStyles.tokensGrid}>
              {selectedSharableTokens &&
                selectedSharableTokens.map((val) => {
                  return (
                    <TokensCard
                      key={`card-${val.tokenId}`}
                      token={val}
                      collection={selectedSharableCollection}
                      updateSelectedTokens={updateSelectedTokens}
                      selectedSection={selectedSection}
                    />
                  )
                })}
            </div>
          )}

        {selectedSection === 'user-wallet' && !fetchTokens &&
          (
            <div className={collectionStyles.gridWrap}>
              <div className={collectionStyles.tokensGrid}>
                {selectedUserTokens &&
                  selectedUserTokens.map((val) => {
                    return (
                      <TokensCard
                        key={`card-${val.tokenId}`}
                        token={val}
                        collection={selectedUserTokens}
                        updateSelectedTokens={updateSelectedTokens}
                        selectedSection={selectedSection}
                      />
                    )
                  })}
              </div>
            </div>
          )}

        {selectedTokens && !selectedTokens.length && selectedSection === 'collections' && (
          <>
            <h2 className={collectionStyles.textAbsoluteCenter}>No tokens found!</h2>
          </>
        )}
        {selectedSharableTokens && !selectedSharableTokens.length && selectedSection === 'sharable' && (
          <>
            <h2 className={collectionStyles.textAbsoluteCenter}>No tokens found!</h2>
          </>
        )}
        {selectedUserTokens && !selectedUserTokens.length && selectedSection === 'user-wallet' && (
          <>
            <h2 className={collectionStyles.textAbsoluteCenter}>No tokens found!</h2>
          </>
        )}
      </div>
      <IconsMenu onChangeSection={setSelectedSection} />
      {(selectedSection === 'sharable' || selectedSection === 'user-wallet') && fetchTokens && (
        <PropagateLoader
          color='rgb(59, 159, 111)'
          loading={fetchTokens}
          size={7}
          cssOverride={{
            display: 'block',
            textAlign: 'center',
            marginBottom: '2.5em',
            marginTop: '2.5em'
          }}
          speedMultiplier={1}
          style={{ position: 'absolute', top: '60%', left: '50%' }}
        />
      )}
    </div>
  )
}
