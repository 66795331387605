import React, { useState } from 'react'

import chevron from '../components/assets/chevron-up.svg'
import sideMenuCStyles from './styles/sideMenuContent.module.scss'

import actions from '../redux/actions'
const { useDispatch, setSelectedCollection, setSelectedSharableCollection } = actions

export default function SideMenuContent (props) {
  const { collection, section } = props

  const [inside, setInside] = useState(false)
  const [insideR, setInsideR] = useState(true)
  const insideClick = () => setInside(!inside)
  const rotateIn = () => setInsideR(!insideR)

  const dispatch = useDispatch()

  // set selected collection to fetch tokens
  const setSelectCollectionToFetch = () => {
    if (section === 'collections') {
      dispatch(setSelectedCollection(collection))
    } else {
      dispatch(setSelectedSharableCollection(collection))
    }
  }
  return (
    <>
      {
        collection && (collection._id || collection.publicId) && (
          <div className={sideMenuCStyles.container}>
            <div className={sideMenuCStyles.insideSection}>
              <div
                className={sideMenuCStyles.insideSectionTab}
                onClick={() => {
                  insideClick()
                  rotateIn()
                }}
              >
                {' '}
                {collection.projectLabel && <h3>{collection.projectLabel}</h3>}
                {collection.collectionLabel && <h3>{collection.collectionLabel}</h3>}
                <img
                  className={
                    insideR
                      ? `${sideMenuCStyles.chevron}`
                      : `${sideMenuCStyles.chevronDown}`
                  }
                  src={chevron}
                  alt='chevron icon'
                />{' '}
              </div>
              {inside
                ? (
                  <div className={sideMenuCStyles.insideTabs} onClick={setSelectCollectionToFetch}>
                    <span className={sideMenuCStyles.active}>
                      Tokens {collection.tokens && <span className={sideMenuCStyles.quantity}>({collection.tokens.length})</span>}
                    </span>
                  </div>
                  )
                : null}
            </div>
          </div>)
      }
    </>
  )
}
