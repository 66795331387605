import React, { useState, useRef } from 'react'

// Assets
import exportStyles from './styles/export.module.scss'
import xmark from './assets/xmark.svg'
import qr from './assets/qrcode.svg'
import check from './assets/check.svg'
import QrScanner from './qrScanner'
import warn from './assets/warn.svg'

// Form Lib
import { Formik } from 'formik'

// Services
import { exportProjectToken } from '../services/token'
import { exportUserToken } from '../services/user'
import { exportSharableToken } from '../services/project'

// Spinner
import PropagateLoader from 'react-spinners/PropagateLoader'

// Notification lib
import { toast } from 'react-toastify'

// Redux Functions
import actions from '../redux/actions'

const TOKEN_EXPLORER = process.env.REACT_APP_TOKEN_EXPLORER

const { useDispatch, deleteCollectionToken } = actions

export default function Export (props) {
  const { close, tokenIcon, token, collection, selectedSection } = props
  // States
  const [bodySection, setBodySection] = useState('form') // sections ( 'form' , 'qr-scanner')
  const [txId, setTxId] = useState('')
  const [inFetch, setInFetch] = useState(false)

  const dispatch = useDispatch()

  // Form Ref.
  const formikRef = useRef()

  // QrScanner Error handler
  const onError = (err) => {
    formikRef.current.setFieldError('address', err)
  }
  // QrScanner Result handler
  const onScan = (data) => {
    changeSection('form')

    // add data to input field
    setTimeout(() => {
      formikRef.current.setFieldValue('address', data)
    }, 500)
  }

  // Form validator
  const validateFormValues = (values) => {
    const errors = {}
    const { address } = values

    if (!address) {
      errors.address = 'Address is required'
    } else if (
      !address.match('bitcoincash:') &&
      !address.match('simpleledger:')
    ) {
      // only allows bitcoincash or simpleledger addresses
      errors.address = 'Address must be simpleledger or bitcoincash'
    }

    if (address.match('bitcoincash:')) {
      errors.addressWarning = 'Careful! Not all Bitcoin Cash wallets are token-aware.'
    }

    return errors
  }

  const changeSection = (section) => {
    if (inFetch) return
    setBodySection(section)
  }

  // handle export token
  const exportFromCollection = async (values) => {
    try {
      // show spinner
      setInFetch(true)

      // export token
      const txId = await exportProjectToken(
        token.tokenId,
        values.address,
        collection._id
      )
      if (!txId) {
        throw new Error('Token cant be exported')
      }
      // delete token from redux store ( delete token from view )
      dispatch(deleteCollectionToken({ collectionId: collection._id, token }))

      setTxId(txId)
      setInFetch(false)
      toast.success('Success!')
    } catch (error) {
      console.warn('Error in export/exportFromCollection()', error)
      setInFetch(false)
      toast.error('Error! : ' + error.message)
    }
  }

  // handle export token
  const exportFromUserWallet = async (values) => {
    try {
      console.log('Selected token', token)

      // show spinner
      setInFetch(true)

      // export token
      const txId = await exportUserToken(
        token.tokenId,
        values.address
      )
      if (!txId) {
        throw new Error('Token cant be exported')
      }
      // delete token from redux store ( delete token from view )
      const collectionId = 'user-wallet-tokens'
      dispatch(deleteCollectionToken({ collectionId, token }))

      setTxId(txId)
      setInFetch(false)
      toast.success('Success!')
    } catch (error) {
      console.warn('Error in export/exportUserWallet()', error)
      setInFetch(false)
      toast.error('Error! : ' + error.message)
    }
  }
  // handle export token
  const exportFromSharableCollection = async (values) => {
    try {
      // show spinner
      setInFetch(true)

      // export token
      const txId = await exportSharableToken(
        token.tokenId,
        values.address,
        collection.publicId
      )
      if (!txId) {
        throw new Error('Token cant be exported')
      }
      // delete token from redux store ( delete token from view )
      const collectionId = collection.publicId
      dispatch(deleteCollectionToken({ collectionId, token }))

      setTxId(txId)
      setInFetch(false)
      toast.success('Success!')
    } catch (error) {
      console.warn('Error in export/exportFromSharableCollection()', error)
      setInFetch(false)
      toast.error('Error! : ' + error.message)
    }
  }

  const submit = async (values) => {
    if (selectedSection === 'collections') {
      await exportFromCollection(values)
    } else if (selectedSection === 'sharable') {
      await exportFromSharableCollection(values)
    } else if (selectedSection === 'user-wallet') {
      await exportFromUserWallet(values)
    }
  }

  return (
    <div className={exportStyles.container}>
      <div className={exportStyles.header}>
        <img
          className={exportStyles.xmark}
          src={xmark}
          alt='close btn'
          onKeyDown={close}
          onClick={close}
        />
        <h2>{!txId ? 'Export NFT' : ''}</h2>
      </div>

      {bodySection === 'form' && !inFetch && !txId && (
        <Formik
          initialValues={{ address: '' }}
          onSubmit={submit}
          innerRef={formikRef}
          validate={validateFormValues}
        >
          {(props) => {
            const { values, errors, handleChange, handleSubmit } = props
            return (
              <>
                <span>Send to</span>

                <form onSubmit={handleSubmit}>
                  <div className={exportStyles.inputArea}>
                    <input
                      id='address'
                      className={exportStyles.inputStyle}
                      type='text'
                      placeholder='Enter Address'
                      value={values.address}
                      onChange={handleChange}
                      disabled={inFetch}
                    />
                    {errors.address &&
                      <div className={exportStyles.addressError}>{errors.address}
                      </div>}
                    {selectedSection === 'collections' && errors.addressWarning && (
                      <>
                        <div className={exportStyles.warnSection}>
                          <div className={exportStyles.warnHeader}>
                            <img
                              src={warn}
                              alt='warn logo'
                              className={exportStyles.warnLogo}
                            />
                            <h4>Warning</h4>
                          </div>
                          <p>
                            {errors.addressWarning}
                          </p>
                        </div>
                      </>
                    )}
                    <img
                      className={exportStyles.qrcode}
                      src={qr}
                      alt='qr code icon'
                      onClick={() => changeSection('qr-scanner')}
                    />
                  </div>

                  <div className={exportStyles.middleWrapper}>
                    <img
                      className={exportStyles.nftFile}
                      src={tokenIcon}
                      alt='exported nft'
                    />
                    <button type='submit' className={exportStyles.sendBtn}>
                      Send
                    </button>
                  </div>
                </form>
              </>
            )
          }}
        </Formik>
      )}

      {!inFetch && txId && (
        <div className={exportStyles.successContainer}>
          <img className={exportStyles.check} src={check} alt='success icon' />
          <h2>NFT export successful</h2>
          <span>Check out your transaction on our block explorer:</span>
          <a
            href={`${TOKEN_EXPLORER}/?txid=${txId}`}
            target='_blank'
            without
            rel='noreferrer'
            className={exportStyles.txid}
          >
            TXID: <br /> {txId}
          </a>
        </div>
      )}

      {/** show spinner in fetch */}
      {inFetch && (
        <PropagateLoader
          className={exportStyles.spinner}
          color='#ffffff'
          loading={inFetch}
          size={5}
          cssOverride={{
            display: 'block',
            textAlign: 'center',
            marginBottom: '2.5em',
            marginTop: '2.5em'
          }}
          speedMultiplier={1}
        />
      )}

      {/** show scanner if the section is 'qr-scanner */}
      {bodySection === 'qr-scanner' && (
        <div>
          <QrScanner onError={onError} onScan={onScan} />
        </div>
      )}
    </div>
  )
}
