import axios from 'axios'
import { getUser } from './auth'

const SERVER = process.env.REACT_APP_API_URL
const INBOX_GALLERY_ID = process.env.REACT_APP_INBOX_GALLERY
// const PUBLIC_GALLERY_ID = process.env.REACT_APP_PUBLIC_GALLERY

export const addTokenToInboxGallery = async (tokenId) => {
  const token = getUser().jwt ? getUser().jwt : ''
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/gallery/nft/add`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        tokenData: {
          galleryId: INBOX_GALLERY_ID,
          tokenId
        }
      }
    }
    const result = await axios(options)
    const response = result.data
    return response
  } catch (e) {
    console.warn('Error in token/addTokenToInboxGallery()', e.message)
    throw e
  }
}

// Get gallery data by id
export const getGalleryById = async (galleryId) => {
  const token = getUser().jwt ? getUser().jwt : ''
  try {
    const options = {
      method: 'GET',
      url: `${SERVER}/gallery/${galleryId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }

    const result = await axios(options)
    const response = result.data.gallery
    return response
  } catch (e) {
    console.warn('Error in token/getGalleryById()', e.message)
    throw e
  }
}
