import React, { useState, useRef } from 'react'
import Popup from 'reactjs-popup'

import iconsMenuStyles from './styles/iconsMenu.module.scss'
import profile from '../components/assets/user.svg'
import plus from '../components/assets/plus-solid.svg'
import personal from '../components/assets/private.svg'
// import download from '../components/assets/download.svg'
import sharable from '../components/assets/public.svg'
import wallet from '../components/assets/wallet.svg'
// import NewCollectionModal from './newCollectionModal'
import NewCollectionType from './newCollectionType'
import ProfileInfo from './profileInfo'

export default function IconsMenu (props) {
  const { onChangeSection } = props
  const [current, setCurrent] = useState('collections')
  const ref = useRef()
  const closeTooltip = () => ref.current.close()
  const handleCurrent = (section) => {
    setCurrent(section)
    if (onChangeSection) {
      onChangeSection(section)
    }
  }
  return (
    <div className={iconsMenuStyles.container}>

      <img
        className={
          current === 'collections'
            ? `${iconsMenuStyles.active}`
            : `${iconsMenuStyles.icon}`
        }
        src={personal}
        alt='collections'
        onClick={() => handleCurrent('collections')}
      />
      <img
        className={
          current === 'sharable'
            ? `${iconsMenuStyles.active}`
            : `${iconsMenuStyles.icon}`
        }
        src={sharable}
        alt='sharable'
        onClick={() => handleCurrent('sharable')}
      />
      <Popup
        ref={ref}
        trigger={
          <img
            className={
              current === 'profile'
                ? `${iconsMenuStyles.active}`
                : `${iconsMenuStyles.icon}`
            }
            src={profile}
            alt='profile'
            onClick={() => setCurrent('profile')}
          />
        }
        p
        modal
      >
        <span>
          <ProfileInfo close={closeTooltip} />
        </span>
      </Popup>
      <Popup
        ref={ref}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        trigger={
          <img
            className={
              current === 'new'
                ? `${iconsMenuStyles.active}`
                : `${iconsMenuStyles.icon}`
            }
            src={plus}
            alt='create collection'
            onClick={() => setCurrent('new')}
          />
        }
        p
        modal
      >
        <span>
          {/* <NewCollectionModal close={closeTooltip} /> */}
          <NewCollectionType close={closeTooltip} />
        </span>
      </Popup>
      <img
        className={
          current === 'user-wallet'
            ? `${iconsMenuStyles.active}`
            : `${iconsMenuStyles.icon}`
        }
        src={wallet}
        alt='userWallet'
        onClick={() => handleCurrent('user-wallet')}
      />
    </div>
  )
}
