import React, { useState } from 'react'

import LoginForm from '../components/loginForm'
import LoginInfo from '../components/loginInfo'

// import logo from '../components/assets/logo.png'
import loginStyles from './styles/login.module.scss'
import ClickWrap from '../components/clickwrap'
import Popup from 'reactjs-popup'
import { useNavigate } from 'react-router-dom'

export default function Login (props) {
  const navigate = useNavigate()
  const [success, setSuccess] = useState(false)
  // Login handler
  const onSuccess = () => {
    setSuccess(true)
  }

  // Modal Info handler
  const closeModal = () => {
    navigate('/gallery')
  }
  return (
    <div className={loginStyles.container}>
      {!success &&
        <>
          <div className={loginStyles.login}>
            <h1 className={loginStyles.logoText}>
              TokenTiger <span className={loginStyles.tm}>&trade;</span>{' '}
            </h1>
            <LoginForm {...props} onSuccess={onSuccess} />
          </div>
          <div className={loginStyles.info}>
            <LoginInfo />
          </div>
        </>}
      <Popup
        closeOnDocumentClick={false}
        closeOnEscape={false}
        /*      ref={ref} */
        open={success}
        p
        modal
      >
        <ClickWrap onClose={closeModal} />
      </Popup>
    </div>
  )
}
