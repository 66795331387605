import React, { useState, useEffect, useRef } from 'react'
import { Tooltip } from 'react-tippy'
import Popup from 'reactjs-popup'
import Export from './export'
import Burn from './burn'

// Icons
import paper from './assets/paper.svg'
import fire from './assets/fire.svg'
import question from './assets/question.svg'
import share from './assets/share-nodes.svg'
import tCardStyles from './styles/tokensCard.module.scss'

// Spinner
import PropagateLoader from 'react-spinners/PropagateLoader'

// Service
import { getTokenData, getTokenIcon } from '../services/token'

// Redux Functions
import actions from '../redux/actions'
import SharableModal from './sharableModal'

const TOKEN_EXPLORER = process.env.REACT_APP_TOKEN_EXPLORER

const { useDispatch, updateTokenData } = actions

export default function TokensCard (props) {
  const { token, collection, updateSelectedTokens, selectedSection } = props
  const dispatch = useDispatch()

  const [iconIsLoaded, setIconIsLoaded] = useState(false)

  const [dataIsLoaded, setDataIsLoaded] = useState(false)
  const [tokenIcon, setTokenIcon] = useState(null)

  const ref = useRef()

  // on hide modal
  const closeTooltip = () => {
    ref.current.close()

    // refresh tokens view on close modal
    updateSelectedTokens()
  }

  // fetch the token-icon when the component mounts
  useEffect(() => {
    const fetchIcon = async () => {
      const tokenId = token.tokenId
      const url = await getTokenIcon(tokenId)
      setTokenIcon(url)
      setIconIsLoaded(true)
    }

    if (!iconIsLoaded) {
      fetchIcon()
    }
  }, [iconIsLoaded, token])

  // fetch the token-data when the component mounts
  // this fetch its only for new tokens added recently
  // This functionality is called every time the <localStatus> value of the token changes.
  useEffect(() => {
    const fetchData = async () => {
      const dataResult = await getTokenData([token.tokenId])
      const data = dataResult.result
      const tokenResult = data[0].genesisData

      const collectionId = collection._id ? collection._id : collection.publicId
      // update token full data into redux store
      dispatch(
        updateTokenData({ collectionId, token: tokenResult })
      )
      setTimeout(() => {
        updateSelectedTokens()
      }, 500)
      setTimeout(() => {
        setDataIsLoaded(true)
      }, 1000)
    }

    // fetch token-data if the token has been added rencetly
    if (!dataIsLoaded && token.localStatus === 'NewToken') {
      fetchData()
    }
    // Spinner handlers
    if (token.localStatus === 'loading') {
      setDataIsLoaded(false)
    }
    if (!token.localStatus) {
      setDataIsLoaded(true)
    }
  }, [dataIsLoaded, token, collection, dispatch, updateSelectedTokens])

  const goToExplorer = () => {
    window.open(
      `${TOKEN_EXPLORER}/?tokenid=${token.tokenId}`,
      '_blank'
    )
  }
  return (
    <div className={tCardStyles.container}>
      {iconIsLoaded && tokenIcon && (
        <div className={tCardStyles.file}>
          <img src={tokenIcon} alt='file icon' />
        </div>

      )}
      {dataIsLoaded && collection && (
        <>
          <div className={tCardStyles.textContent}>
            {collection && collection.projectLabel && <h3>{collection.projectLabel}</h3>}
            {collection && collection.collectionLabel && <h3>{collection.collectionLabel}</h3>}

            <h4>{token.name}</h4>
          </div>
          <div className={tCardStyles.icons}>
            <Popup
              ref={ref}
              closeOnDocumentClick={false}
              closeOnEscape={false}
              trigger={<img src={paper} alt='export token' />}
              p
              modal
            >
              <span>
                <Export
                  close={closeTooltip}
                  tokenIcon={tokenIcon}
                  collection={collection}
                  token={token}
                  selectedSection={selectedSection}
                />
              </span>
            </Popup>
            <Popup
              ref={ref}
              closeOnDocumentClick={false}
              closeOnEscape={false}
              trigger={<img src={fire} alt='burn token' />}
              p
              modal
            >
              <span>
                <Burn
                  close={closeTooltip}
                  collection={collection}
                  token={token}
                  selectedSection={selectedSection}
                />
              </span>
            </Popup>
            <Popup
              ref={ref}
              closeOnDocumentClick={false}
              closeOnEscape={false}
              trigger={<img src={share} alt='share token' />}
              p
              modal
              disabled={!collection._id} // disabled for sharables
            >
              <span>
                <SharableModal
                  collection={collection}
                  token={token}
                  close={closeTooltip}
                />
              </span>
            </Popup>
            <Tooltip
              title='Some delay may be expected between creating a NFT and all NFT data being displayed'
              position='top-start'
              trigger='mouseenter'
              size='small'
              inertia='true'
              theme='dark'
            >
              <img src={question} alt='token info' onClick={goToExplorer} />
            </Tooltip>
          </div>
        </>
      )}

      {(!iconIsLoaded || !dataIsLoaded) && (
        <PropagateLoader
          color='#ffffff'
          loading={!iconIsLoaded || !dataIsLoaded}
          size={5}
          cssOverride={{
            display: 'block',
            textAlign: 'center',
            marginBottom: '2.5em',
            marginTop: '2.5em'
          }}
          speedMultiplier={1}
        />
      )}
    </div>
  )
}
