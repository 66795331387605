import CollectionReducer from './collections'
import CreateFormReducer from './create-form'
import GalleryReducer from './gallery'
import CreditReducer from './credits'

export const reducer = {
  collections: CollectionReducer,
  createForm: CreateFormReducer,
  galleries: GalleryReducer,
  credit: CreditReducer
}
