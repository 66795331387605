import React from 'react'

import userWalletDataStyles from './styles/userWalletData.module.scss'

import copy from './assets/copy.svg'

import { toast } from 'react-toastify'

export default function SharableCollectionData (props) {
  const { close, collection } = props

  // Copy sharable link to clipboard
  const copyToClipboard = () => {
    window.navigator.clipboard.writeText(collection.slpAddress)
    toast.success('Copied to clipboard!')
    close()
  }
  return (
    <div className={userWalletDataStyles.container}>
      {collection && <h3><strong>{collection.collectionLabel}  </strong> Address</h3>}
      {collection && collection.slpAddress && (
        <>
          <p>Copy the address below and share it with friends! <br /> They will be able to send you NFTs to this address</p>
          <hr />
          <span className={userWalletDataStyles.link}>
            {collection.slpAddress}
          </span>
          <img className={userWalletDataStyles.copyBtn} src={copy} alt='copy button' onClick={copyToClipboard} />
          <hr />
        </>
      )}

      <button
        className={userWalletDataStyles.closeBtn}
        onKeyDown={close}
        onClick={close}
      >
        Close
      </button>
    </div>
  )
}
