/** Redux Store in order to save the gallery data  */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  galleries: {}
}

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    addDataToGallery: (state, action) => {
      const galleryId = action.payload.galleryId
      const tokenId = action.payload.tokenId
      const data = action.payload.tokenData

      const galleries = state.galleries
      if (!galleries[galleryId]) {
        galleries[galleryId] = {}
      }

      galleries[galleryId][tokenId] = data

      state.galleries = galleries
    }
  }
})

// Action creators are generated for each case reducer function
export const { addDataToGallery } = gallerySlice.actions

export default gallerySlice.reducer
