import React from 'react'
import { motion } from 'framer-motion'
import Typewriter from 'typewriter-effect'

import img from './assets/art3.svg'

import tCModalStyles from './styles/tokenCreationModal.module.scss'

export default function TokenCreationModal (props) {
  const { loadingMsg } = props
  return (
    <div className={tCModalStyles.container}>
      <motion.img
        dragSnapToOrigin='true'
        drag='x'
        dragTransition={{ bounceStiffness: 800, bounceDamping: 20 }}
        dragConstraints={{ left: 50, right: 50 }}
        animate={{
          scale: [1, 1.1, 1]
        }}
        transition={{
          repeat: Infinity,
          duration: 10
        }}
        src={img}
        alt='animated icon example'
      />
      <h1>
        <Typewriter
          options={{
            strings: ['We are creating your token', 'Please wait...'],
            autoStart: true,
            loop: true,
            deleteSpeed: 3,
            delay: 70
          }}
        />
      </h1>
      <motion.h3
        animate={{
          opacity: [
            0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 0.9, 0.8, 0.7, 0.6, 0.5, 0.4,
            0.3
          ]
        }}
        transition={{
          repeat: Infinity,
          duration: 3
        }}
      >
        {loadingMsg && loadingMsg}

      </motion.h3>
    </div>
  )
}
