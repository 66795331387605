/** Redux Store in order to maintain the create form values  */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: {},
  uppyStarted: false,
  username: ''
}

export const createFormSlice = createSlice({
  name: 'createForm',
  initialState,
  reducers: {
    // add an array of collections
    addCreateFormValues: (state, action) => {
      state.value = action.payload
    },
    resetCreateFormValues: () => {
      return initialState
    },
    uppyStarted: (state) => {
      state.uppyStarted = true
    },
    updateUsername: (state, action) => {
      state.username = action.payload
    }

  }
})

// Action creators are generated for each case reducer function
export const {
  addCreateFormValues,
  resetCreateFormValues,
  uppyStarted,
  updateUsername
} = createFormSlice.actions

export default createFormSlice.reducer
