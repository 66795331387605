import axios from 'axios'
import { getUser } from './auth'

const SERVER = process.env.REACT_APP_API_URL

// Generate image request
export const generateImage = async (input = {}) => {
  const token = getUser().jwt ? getUser().jwt : ''
  try {
    const options = {
      method: 'POST',
      url: `${SERVER}/openai/generate`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        openai: input
      }
    }

    const result = await axios(options)
    const response = result.data
    return response
  } catch (e) {
    console.warn('Error in openai/generateImage()', e.message)
    throw e
  }
}

// Get file Blob
export const getFile = async (url) => {
  try {
    const token = getUser().jwt ? getUser().jwt : ''

    const options = {
      method: 'POST',
      url: `${SERVER}/openai/download`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        openai: { url }
      },
      responseType: 'blob'
    }
    const result = await axios(options)
    const response = result.data
    return response
  } catch (e) {
    console.log('Error in services/dispute.js/getFile()')
    throw e
  }
}
