import React from 'react'

import clickwrapStyles from './styles/clickwrap.module.scss'

export default function ClickWrap (props) {
  return (
    <div className={clickwrapStyles.container}>
      <div className={clickwrapStyles.header}>
        <h1 className={clickwrapStyles.logoText}>
          TokenTiger <span className={clickwrapStyles.tm}>&trade;</span>
        </h1>
      </div>
      <div className={clickwrapStyles.content}>
        <h3>
          Welcome to{' '}
          <span className={clickwrapStyles.boldText}>Token Tiger! &trade;</span>
        </h3>
        <p>
          Token Tiger is alpha release software that focuses on making creation
          of SLP NFTs easy. The current software is relatively new and may be
          buggy. Please report any issues, comments, questions, requested
          features, etc. to{' '}
          <a href='mailto: tokentiger@launchpadip.com'>
            tokentiger@launchpadip.com
          </a>
          . By clicking below, you agree to hold Token Tiger, Dos Centavos LLC,
          and Launchpad Intellectual Property harmless. Token Tiger uses
          custodial wallets to track NFTs and uses pinned IPFS files to hold NFT
          "payloads". If you want to ensure you do not lose your NFT, please
          consider sending it to a non-custodial wallet -- for example,{' '}
          <a
            href='https://wallet.fullstack.cash'
            target='_blank'
            rel='noopener noreferrer'
          >
            wallet.fullstack.cash
          </a>
          . Token Tiger is currently free. We anticipate charging for making
          NFTs in future software releases. Enjoy!
        </p>
      </div>
      <div className={clickwrapStyles.bottom}>
        <button
          className={clickwrapStyles.acceptBtn}
          onClick={() => props.onClose && props.onClose()}
        >
          OK
        </button>
      </div>
    </div>
  )
}
