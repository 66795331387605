import axios from 'axios'
import { getUser } from './auth'
const SERVER = process.env.REACT_APP_API_URL

export const newMacroPayload = async (
  metadata,
  encryptionFileName,
  ignoreEncryption
) => {
  try {
    // Get the JWT token for the currently logged-in user.
    const token = getUser().jwt ? getUser().jwt : ''
    const options = {
      method: 'POST',
      url: `${SERVER}/macro/metadata/`,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      data: {
        metadata,
        encryptionFileName,
        ignoreEncryption
      }
    }

    const result = await axios(options)
    const response = result.data // await data.json()
    return response
  } catch (err) {
    console.warn('Error in payload/newMacroPayload()', err)
    throw err
  }
}
// Get  payload by project id
export const getPayloadByProject = async (projectId) => {
  const token = getUser().jwt ? getUser().jwt : ''

  // Try to get  payload by id
  try {
    const options = {
      url: `${SERVER}/metadatas/byproject/${projectId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }
    const result = await axios(options)
    const response = result.data
    return response
  } catch (err) {
    console.warn('Error in payload/getPayloadByProject()', err.message)
    return false
  }
}
