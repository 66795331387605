import React, { useState, useEffect } from 'react'

// import heart from './assets/heart.svg'
// import comment from './assets/comment.svg'

import galleryCardStyles from './styles/galleryCard.module.scss'

// Spinner
import PropagateLoader from 'react-spinners/PropagateLoader'

export default function GalleryCard (props) {
  const { index, tokenId, localGalleryData } = props
  const [tokenIcon, setTokenIcon] = useState(false)
  const [tokenName, setTokenName] = useState(false)
  const [imgLoaded, setImgLoaded] = useState(false)
  const [ownerUsername, setOwnerUsername] = useState(false)

  // Get token icon from redux on component did mount
  useEffect(() => {
    const tokenData = localGalleryData[tokenId]
    if (tokenData) {
      setTokenIcon(tokenData.tokenIcon)
      setTokenName(tokenData.tokenName)
      setOwnerUsername(tokenData.ownerUsername)
    }
  }, [tokenId, localGalleryData])

  return (
    <>
      <div id={tokenId + index} className={galleryCardStyles.container}>
        {/** Display if token icon exist */}
        {tokenIcon && (

          <>
            <img src={tokenIcon} alt='Token icon' onLoad={() => { setImgLoaded(true) }} />
            {imgLoaded && (
              <>
                {/* Code bellow commented out until likes and comments are implemented */}
                {/* <div className={galleryCardStyles.commentSection}>
                  <img
                    className={galleryCardStyles.commentBtn}
                    src={comment}
                    alt='comment button'
                  />
                  <span>0</span>
                </div>

                <div className={galleryCardStyles.likeSection}>
                  <img
                    className={galleryCardStyles.likeBtn}
                    src={heart}
                    alt='like button'
                  />
                  <span>0</span>
                </div> */}
                <div className={galleryCardStyles.namesSection}>
                  <h3>{tokenName}</h3>
                  <h3>{ownerUsername || 'Anonymous'}</h3>
                </div>
              </>
            )}
          </>)}

        {/** Loading spinner */}
        {!tokenIcon && (

          <div className={galleryCardStyles.spinnerContainer}>
            <PropagateLoader
              color='rgb(59, 159, 111)'
              loading={!tokenIcon}
              size={7}
              cssOverride={{
                display: 'block',
                textAlign: 'center',
                marginBottom: '2.5em',
                marginTop: '2.5em'
              }}
              speedMultiplier={1}
              style={{ position: 'absolute', top: '60%', left: '50%' }}
            />
          </div>
        )}
      </div>
    </>
  )
}
