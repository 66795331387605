import React, { useState, useEffect, useCallback, useRef } from 'react'
import GalleryCard from '../components/galleryCard'

import galleryStyles from './styles/gallery.module.scss'

import { getGalleryById } from '../services/gallery'
import { getUsers } from '../services/user'

import { getTokenIcon, getTokenData } from '../services/token'
// Spinner
import PropagateLoader from 'react-spinners/PropagateLoader'

// Redux Functions
import actions from '../redux/actions'

// Notification lib
import { toast } from 'react-toastify'

// public gallery id
const PUBLIC_GALLERY_ID = process.env.REACT_APP_PUBLIC_GALLERY

// Redux actions
const {
  useSelector,
  useDispatch,
  addDataToGallery
} = actions

export default function Gallery () {
  const [galleryInFetch, setGalleryInFetch] = useState(false) // spinner for gallery data
  const [selectedGallery] = useState(PUBLIC_GALLERY_ID) // selected gallery
  const [galleryData, setGalleryData] = useState(null) // gallery data
  const [loadedTokens, setLoadedTokens] = useState([]) // fetched tokens data
  const [usersData, setUsersData] = useState([]) // fetched tokens data
  const [loadingGalleryError, setLoadingGalleryError] = useState(false) // fetched tokens data

  const dispatch = useDispatch()

  // variables for callbacks
  const galleryDataRef = useRef()
  const loadedTokensRef = useRef()
  const tokensInFetchRef = useRef()
  const usersDataRef = useRef()

  // Get gallery data from redux store
  const localGalleryData = useSelector((state) => state.galleries.galleries)

  useEffect(() => {
    loadedTokensRef.current = loadedTokens
  }, [loadedTokens])

  useEffect(() => {
    usersDataRef.current = usersData
  }, [usersData])

  // Get token owner username
  const getUserNameByTokenId = useCallback((galleryData, tokenId) => {
    let ownerId = ''
    let userName = ''
    // map all contributing in order to get the contributor id
    const contributingUsers = galleryData.contributingUsers
    for (let i = 0; i < contributingUsers.length; i++) {
      const contributor = contributingUsers[i]
      // verify if the token belongs to this  contributor
      const foundOwner = contributor.tokenIds.find((val) => { return val === tokenId })

      if (foundOwner) {
        ownerId = contributor.userId
        break
      }
    }
    // If owner exist get his username
    if (ownerId) {
      const user = usersDataRef.current.find((val) => { return val._id.toString() === ownerId })
      if (user) {
        userName = user.username
      }
    }
    return userName
  }, [])
  // Fetch token data
  const fetchTokenData = useCallback(async (galleryData) => {
    try {
      // Limit data to load
      const Limit = 10
      const newLoadeds = []
      const loadedTokens = loadedTokensRef.current
      // Return if gallery data is not provided
      if (!galleryData) return
      // return if tokens are already in fetch
      if (loadedTokens.length === galleryData.tokenIds.length || tokensInFetchRef.current) return
      tokensInFetchRef.current = true

      // Tokens to fetch
      const tokenIdsToFetch = galleryData.tokenIds.slice(loadedTokens.length, loadedTokens.length + Limit)
      const tokensDataResult = await getTokenData(tokenIdsToFetch)
      const tokenData = tokensDataResult.result

      // Map each token id and get it icon
      for (let i = 0; i < tokenIdsToFetch.length + Limit; i++) {
        const tokenId = tokenIdsToFetch[i]
        const tData = tokenData[i]

        if (tokenId) {
          // Verify for existing icon stored in redux
          const existingGalleryData = localGalleryData[PUBLIC_GALLERY_ID]
          if (existingGalleryData) {
            const existingTokenData = existingGalleryData[tokenId]
            // continue with the next one , if the icon exist
            if (existingTokenData) {
              newLoadeds.push(tokenId)
              continue
            }
          }
          const ownerUsername = getUserNameByTokenId(galleryData, tokenId)
          //  Get token icon
          const tokenIcon = await getTokenIcon(tokenId)
          const data = {
            tokenId,
            galleryId: selectedGallery,
            tokenData: {
              tokenIcon,
              tokenName: tData.genesisData.name,
              ownerUsername
            }
          }

          // Save data in redux store
          dispatch(addDataToGallery(data))
          newLoadeds.push(tokenId)
        }
      }

      // update gallery array
      setLoadedTokens(loadedTokens.concat(newLoadeds))
      tokensInFetchRef.current = false
      setGalleryInFetch(false)
    } catch (error) {
      console.warn(error)
      tokensInFetchRef.current = false
      setGalleryInFetch(false)
    }
  }, [
    setLoadedTokens,
    setGalleryInFetch,
    dispatch,
    localGalleryData,
    selectedGallery,
    getUserNameByTokenId
  ])

  useEffect(() => {
    // Get gallery users data
    const getUsersData = async () => {
      try {
        // Fetch users
        const usersResp = await getUsers()
        setUsersData(usersResp.users)
      } catch (error) {
        console.warn(error)
        throw error
      }
    }
    // Get gallery data
    const getGallery = async () => {
      try {
        setGalleryInFetch(true)

        const gallery = await getGalleryById(selectedGallery)

        // Change the gallery order to show the last tokens added first
        gallery.tokenIds.reverse()

        galleryDataRef.current = gallery

        await getUsersData()

        // fetch the first tokens after gallery loaded successfully
        fetchTokenData(galleryDataRef.current)
        setGalleryData(gallery)
      } catch (error) {
        setGalleryInFetch(false)
        toast.error('Error! : ' + error.message)
        setLoadingGalleryError(error.message)
      }
    }

    // Get gallery data if doesnt exist
    if (!galleryData && galleryInFetch === false && !loadingGalleryError) {
      getGallery()

      // Set a scroll event
      window.onscroll = function () {
        // verify if the scroll bar is at the bottom
        const totalPageHeight = document.body.scrollHeight
        const scrollPoint = window.scrollY + window.innerHeight

        // Load next tokens
        if (scrollPoint >= totalPageHeight - 50) {
          if (!tokensInFetchRef.current) {
            fetchTokenData(galleryDataRef.current)
          }
        }
      }
    }
  }, [galleryData, fetchTokenData, galleryInFetch, selectedGallery, loadingGalleryError])

  return (
    <>
      {/* Header Section */}
      <div className={galleryStyles.header}>
        <h1>Explore</h1>
        <div className={galleryStyles.galleryTypeSection}>
          <div
            className={`${galleryStyles.galleryTypeBtn} ${galleryStyles.selected}`}
          >
            Public Gallery
          </div>
          <div className={galleryStyles.galleryTypeBtn}>Featured</div>
        </div>
      </div>
      {/* Gallery Grid */}
      <div className={galleryStyles.gallery}>
        {!galleryInFetch && loadedTokens.map((val, i) => {
          return <GalleryCard key={`galleryCard-${i}`} index={i} localGalleryData={localGalleryData[selectedGallery]} tokenId={val} />
        })}
      </div>
      {/** Center Spinner for gallery fetch */}
      {galleryInFetch && (
        <PropagateLoader
          color='rgb(59, 159, 111)'
          loading={galleryInFetch}
          size={7}
          cssOverride={{
            display: 'block',
            textAlign: 'center',
            marginBottom: '2.5em',
            marginTop: '2.5em'
          }}
          speedMultiplier={1}
          style={{ position: 'absolute', top: '60%', left: '50%' }}
        />
      )}
      {/** Bottom Spinner for tokens fetch */}
      {!galleryInFetch && tokensInFetchRef.current && (
        <PropagateLoader
          color='rgb(59, 159, 111)'
          loading={!galleryInFetch && tokensInFetchRef.current}
          size={7}
          cssOverride={{
            display: 'block',
            textAlign: 'center',
            marginBottom: '2.5em',
            marginTop: '2.5em'
          }}
          speedMultiplier={1}
          style={{ position: 'absolute', botton: '2%', left: '50%' }}
        />
      )}
      {loadingGalleryError && <h2 className={galleryStyles.errMsg}>Gallery could not be loaded!</h2>}
    </>
  )
}
