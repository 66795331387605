import React, { useState } from 'react'

import headerStyles from './styles/header.module.scss'
// import logo from "./assets/logo.png";
import { Link } from 'react-router-dom'
import Profile from './profile'
import Theme from './theme'

export default function Header () {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  return (
    <div className={headerStyles.container}>
      {/* <img className={headerStyles.logo} src={logo} alt="token-tiger logo" /> */}
      <h1 className={headerStyles.logoText}>
        TokenTiger <span className={headerStyles.tm}>&trade;</span>
      </h1>
      <div className={headerStyles.bar}>
        <nav className={headerStyles.navigation}>
          <Theme />
          <button
            className={headerStyles.hamburger}
            onClick={() => {
              setIsNavExpanded(!isNavExpanded)
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
              viewBox='0 0 20 20'
              fill='white'
            >
              <path
                fillRule='evenodd'
                d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z'
                clipRule='evenodd'
              />
            </svg>
          </button>
          <div
            className={
              isNavExpanded
                ? `${headerStyles.navigationMenu} ${headerStyles.expanded}`
                : headerStyles.navigationMenu
            }
          >
            <ul>
              <li>
                <Link to='/gallery' onClick={() => setIsNavExpanded(false)}>
                  Gallery
                </Link>
              </li>

              <li>
                <Link to='/collections' onClick={() => setIsNavExpanded(false)}>
                  My Collections
                </Link>
              </li>
              <li>
                <Link to='/FAQ' onClick={() => setIsNavExpanded(false)}>
                  FAQs
                </Link>
              </li>
              <li>
                <Link to='/create' onClick={() => setIsNavExpanded(false)}>
                  Create
                </Link>
              </li>
              <li>
                <Link to='/studio' onClick={() => setIsNavExpanded(false)}>
                  Studio
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <Profile />
    </div>
  )
}
