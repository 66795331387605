import React, { useState } from 'react'

import { Formik } from 'formik'
import * as EmailValidator from 'email-validator'

import eye from './assets/eye.svg'
import eyeslash from './assets/eyeslash.svg'

import loginFormStyles from './styles/loginForm.module.scss'
import { handleLogin, getUser, setUser } from '../services/auth'
import { createUser, getWalletAddresses, resetPassword } from '../services/user'
import { toast } from 'react-toastify'
import PropagateLoader from 'react-spinners/PropagateLoader'
import actions
  from '../redux/actions'

const { useDispatch, resetCollections, resetCreateFormValues, updateUsername } = actions
const LoginForm = (props) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [isIcon1Visible, setIcon1Visible] = useState(false)// Toggle password eye icons

  // Reset password request
  const resetPasswordHandler = async (values) => {
    try {
      await resetPassword(values.email)
    } catch (err) {
      if (err.message.match('User not found')) {
        err.message = 'Account not found. Please check the spelling of the email address and try again.'
      }
      throw err
    }
  }

  // Login request
  const loginHandler = async (values) => {
    try {
      await handleLogin(values)
    } catch (err) {
      if (err && err.response && err.response.status === 401) {
        err.message = 'The email or password is incorrect.'
      }
      throw err
    }
  }
  // Create User request
  const newUserHandler = async (values) => {
    try {
      await createUser(values)
    } catch (err) {
      if (err && err.response && err.response.data.match('Email already in use')) {
        err.message = 'Email already in use. Try a different one.'
      }
      throw err
    }
  }
  const submit = (values, { setSubmitting }) => {
    setTimeout(async () => {
      try {
        setSubmitting(true)
        setLoading(true)

        // Reset password handler
        if (values.isResetPassword) {
          await resetPasswordHandler(values)
          setLoading(false)
          setSubmitting(false)
          toast.success('Password reset successful! Check your email.')
          return
        }
        // Create / Login handler
        if (!values.isCreateBtn) {
          await loginHandler(values)
        } else {
          await newUserHandler(values)
        }

        const userAddresses = await getWalletAddresses()
        console.log(userAddresses)

        const user = getUser()
        const updatedUser = Object.assign({}, user)
        updatedUser.addresses = userAddresses.addresses
        setUser(updatedUser)

        // reset old redux state from store
        dispatch(resetCollections())
        dispatch(resetCreateFormValues())

        // set username into redux store
        dispatch(updateUsername(user.username))

        setTimeout(() => {
          toast.success('Login Success!')
          setLoading(false)
          if (props.onSuccess) {
            props.onSuccess()
          }
        }, 1000)
      } catch (err) {
        values.isCreateBtn = false
        setSubmitting(false)
        setTimeout(() => {
          setLoading(false)
          toast.error(err.message, { autoClose: 7000 })
        }, 1000)
      }
    }, 500)
  }

  const toggleIcon = () => {
    setIcon1Visible(!isIcon1Visible)
  }

  return (

    <Formik
      initialValues={{ email: '', password: '', isCreateBtn: false, isResetPassword: false }}
      onSubmit={submit}
      validate={(values) => {
        const errors = {}
        if (values.isResetPassword) {
          if (!values.email) {
            errors.email = 'Required'
          }
          return errors
        }
        if (!values.email) {
          errors.email = 'Required'
        } else if (!EmailValidator.validate(values.email)) {
          errors.email = 'Invalid email address.'
        }

        if (!values.password) {
          errors.password = 'Required'
        }
        return errors
      }}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props
        return (
          <div className={loginFormStyles.container}>
            <h1>Login</h1>
            <form onSubmit={handleSubmit}>
              <label htmlFor='email'>Email</label>
              <div className={loginFormStyles.emailSection}>
                <input
                  id='email'
                  name='email'
                  type='text'
                  placeholder='Enter your email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.email && touched.email && 'error'}
                />
                {errors.email && touched.email && (
                  <div className={loginFormStyles.inputFeedback}>
                    {errors.email}
                  </div>
                )}
              </div>

              <label htmlFor='password'>Password</label>
              <div className={loginFormStyles.passwordSection}>
                <input
                  id='password'
                  name='password'
                  type={isIcon1Visible ? 'text' : 'password'}
                  placeholder='Enter your password'
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={errors.password && touched.password && 'error'}
                />
                <div onClick={toggleIcon}>
                  {isIcon1Visible
                    ? (
                      <img className={loginFormStyles.eyeIcon} src={eye} alt='eye icon' />
                      )
                    : (
                      <img className={loginFormStyles.eyeIcon} src={eyeslash} alt='eyeslash icon' />
                      )}

                </div>

              </div>

              {errors.password && touched.password && (
                <div className={loginFormStyles.inputFeedback}>
                  {errors.password}
                </div>
              )}
              <PropagateLoader
                color='#ffffff'
                loading={loading}
                size={5}
                cssOverride={{ display: 'block', textAlign: 'center', marginBottom: '2.5em' }}
                speedMultiplier={1}
              />
              <div className={loginFormStyles.btnContainer}>
                <button className={loginFormStyles.btn} type='submit' disabled={isSubmitting} onClick={() => { values.isCreateBtn = false; values.isResetPassword = false }}>
                  Login
                </button>
                <button className={loginFormStyles.btn} type='submit' disabled={isSubmitting} onClick={() => { values.isCreateBtn = true; values.isResetPassword = false }}>
                  Create
                </button>
              </div>

              <button className={loginFormStyles.reset} type='submit' disabled={isSubmitting} onClick={() => { values.isResetPassword = true }}>
                Can't log in? Reset password
              </button>

            </form>
            <p className={loginFormStyles.copyright}>
              Copyright 2023 - {new Date().getFullYear()} All rights
              reserved - Patent Pending
            </p>
          </div>
        )
      }}
    </Formik>
  )
}

export default LoginForm
