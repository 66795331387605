import React, { useState, useRef } from 'react'
import Popup from 'reactjs-popup'

import cTopMenuStyles from './styles/collectionsTopMenu.module.scss'
import plus from '../components/assets/plus.png'
// import NewCollectionModal from './newCollectionModal'
import NewCollectionType from './newCollectionType'

export default function CollectionsTopMenu (props) {
  const { onChangeSection } = props
  const [current, setCurrent] = useState('collections')
  const ref = useRef()
  const closeTooltip = () => ref.current.close()

  const handleCurrent = (section) => {
    setCurrent(section)
    if (onChangeSection) {
      onChangeSection(section)
    }
  }
  return (
    <div className={cTopMenuStyles.container}>
      <span
        className={
          current === 'collections'
            ? `${cTopMenuStyles.active}`
            : `${cTopMenuStyles.tab}`
        }
        onClick={() => handleCurrent('collections')}
      >
        Personal Collections
      </span>
      <span
        className={
          current === 'sharable'
            ? `${cTopMenuStyles.active}`
            : `${cTopMenuStyles.tab}`
        }
        onClick={() => handleCurrent('sharable')}
      >
        Sharable Collections
      </span>
      {/* <span
        className={
          current === 'downloads'
            ? `${cTopMenuStyles.active}`
            : `${cTopMenuStyles.tab}`
        }
        onClick={() => setCurrent('downloads')}
      >
        Downloads
      </span> */}
      <Popup
        ref={ref}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        trigger={
          <span
            className={
              current === 'new'
                ? `${cTopMenuStyles.active}`
                : `${cTopMenuStyles.tab}`
            }
            onClick={() => setCurrent('new')}
          >
            {' '}
            <img
              className={cTopMenuStyles.plus}
              src={plus}
              alt='create new collection'
            />{' '}
            New Collection
          </span>
        }
        p
        modal
      >
        <span>
          {/* <NewCollectionModal close={closeTooltip} /> */}
          <NewCollectionType close={closeTooltip} />
        </span>
      </Popup>

      <span
        className={
          current === 'user-wallet'
            ? `${cTopMenuStyles.active}`
            : `${cTopMenuStyles.tab}`
        }
        onClick={() => handleCurrent('user-wallet')}
      >
        User Wallet
      </span>
    </div>
  )
}
