import React, { useState } from 'react'

import newCTypeStyles from './styles/newCollectionType.module.scss'
import publicIcon from './assets/public.svg'
import privateIcon from './assets/private.svg'
import { newProject, newSharableCollection } from '../services/project'
import PropagateLoader from 'react-spinners/PropagateLoader'
import { toast } from 'react-toastify'

// Redux Functions
import actions from '../redux/actions'
const {
  useDispatch,
  pushCollection,
  pushSharable
} = actions

function NewCollectionType (props) {
  const { close } = props
  const dispatch = useDispatch()
  const [showPrivate, setShowPrivate] = useState(false)
  const [showPublic, setShowPublic] = useState(false)
  const [inFetch, setInFetch] = useState(false)
  const [publicCollectionName, setPublicCollectionName] = useState('')
  const [privateCollectionName, setPrivateCollectionName] = useState('')

  const handlePrivateClick = () => {
    setShowPrivate(true)
    setShowPublic(false)
  }

  const handlePublicClick = () => {
    setShowPublic(true)
    setShowPrivate(false)
  }

  const handleBackClick = () => {
    setShowPrivate(false)
    setShowPublic(false)
  }

  const onChangePublicCollectionName = (e) => {
    const value = e.target.value
    setPublicCollectionName(value)
  }
  const onChangePrivateCollectionName = (e) => {
    const value = e.target.value
    setPrivateCollectionName(value)
  }
  const handlePrivateSubmit = async () => {
    try {
      setInFetch(true)
      const project = {
        schemaVersion: 1,
        projectLabel: privateCollectionName
      }
      const result = await newProject(project)
      dispatch(pushCollection(result.project))
      toast.success('Success!')
      setInFetch(false)
      setPrivateCollectionName('')
      if (close) close()
    } catch (error) {
      toast.error(error.message)
      setInFetch(false)
    }
  }

  const handlePublicSubmit = async () => {
    try {
      setInFetch(true)

      const result = await newSharableCollection(publicCollectionName)
      // console.log("new sharable result", result)

      // dispatch(pushCollection(result.project))
      toast.success('Success!')
      setInFetch(false)
      setPublicCollectionName('')
      dispatch(pushSharable(result))
      if (close) close()
    } catch (error) {
      toast.error(error.message)
      setInFetch(false)
    }
  }

  return (
    <div>
      {
        !showPrivate && !showPublic && (
          <div className={newCTypeStyles.container}>
            <h1>Select Collection Type</h1>
            <p>
              Here you can select the type of collection you want to create, a Personal
              Collection is only visible for you, a Sharable Collection can be shared
              with other TokenTiger users!
            </p>
            <div className={newCTypeStyles.imgSectionContainer}>
              <div
                className={`${newCTypeStyles.imgSection} ${newCTypeStyles.effect2}`}
                onClick={handlePrivateClick}
              >
                <h2>Personal</h2>
                <img src={privateIcon} alt='Private Collection' />
              </div>
              <div
                className={`${newCTypeStyles.imgSection} ${newCTypeStyles.effect}`}
                onClick={handlePublicClick}
              >
                <h2>Sharable</h2>
                <img src={publicIcon} alt='Public Collection' />
              </div>
            </div>
            <button
              className={newCTypeStyles.closeBtn}
              onKeyDown={close}
              onClick={close}
            >
              Close
            </button>
          </div>
        )
      }

      {/** Public Collection View */}
      {showPublic && (
        <div className={newCTypeStyles.container}>
          <h3>Create a new sharable collection</h3> <hr />
          <div className={newCTypeStyles.inputSection}>
            <label>Sharable Collection Name</label>
            <input
              className={newCTypeStyles.inputStyle}
              type='text'
              name='publicCollection'
              id='publicCollection'
              autoFocus
              placeholder=''
              value={publicCollectionName}
              onChange={onChangePublicCollectionName}
            />
            {inFetch && (
              <PropagateLoader
                color='#ffffff'
                loading={inFetch}
                size={5}
                cssOverride={{ display: 'block', textAlign: 'center', marginBottom: '1.5em' }}
                speedMultiplier={1}
              />
            )}
            <button className={newCTypeStyles.submitBtn} onClick={handlePublicSubmit} disabled={inFetch}>Submit</button>
          </div>{' '}
          <hr />
          <div className={newCTypeStyles.btnSection}>
            <button className={newCTypeStyles.backBtn} onClick={handleBackClick}>
              Back
            </button>
            <button
              className={newCTypeStyles.closeBtn}
              onKeyDown={close}
              onClick={close}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/** Private Collection View */}

      {showPrivate && (
        <div className={newCTypeStyles.container}>
          <h3>Create a new personal collection</h3> <hr />
          <div className={newCTypeStyles.inputSection}>
            <label>Personal Collection Name</label>
            <input
              className={newCTypeStyles.inputStyle}
              type='text'
              name='privateCollection'
              id='privateCollection'
              autoFocus
              placeholder=''
              value={privateCollectionName}
              onChange={onChangePrivateCollectionName}
            />
            {inFetch && (
              <PropagateLoader
                color='#ffffff'
                loading={inFetch}
                size={5}
                cssOverride={{ display: 'block', textAlign: 'center', marginBottom: '1.5em' }}
                speedMultiplier={1}
              />
            )}
            <button className={newCTypeStyles.submitBtn} onClick={handlePrivateSubmit} disabled={inFetch}>Submit</button>
          </div>{' '}
          <hr />
          <div className={newCTypeStyles.btnSection}>
            <button className={newCTypeStyles.backBtn} onClick={handleBackClick}>
              Back
            </button>
            <button
              className={newCTypeStyles.closeBtn}
              onKeyDown={close}
              onClick={close}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default NewCollectionType
