import React, { useState } from 'react'
import { Tooltip } from 'react-tippy'

// Services
import { exportProjectToken } from '../services/token'
import { newSharableCollection, updateSharableCollection } from '../services/project'

// Styles
import sharableMStyles from './styles/sharableModal.module.scss'

// Notification lib
import { toast } from 'react-toastify'

// Spinner
import PropagateLoader from 'react-spinners/PropagateLoader'

// Icons
import check from './assets/check.svg'
import edit from './assets/edit.svg'
import xMark from '../components/assets/xmark.svg'

// Redux
import actions from '../redux/actions'

const TOKEN_EXPLORER = process.env.REACT_APP_TOKEN_EXPLORER

const {
  useSelector,
  useDispatch,
  deleteCollectionToken,
  addTokenToCollection,
  editSharable,
  pushSharable,
  addSharables
} = actions
export default function SharableModal (props) {
  const { close, token, collection } = props

  // redux functions
  const dispatch = useDispatch()
  const mySharableCollections = useSelector((state) => state.collections.sharables)

  // states
  const [inFetch, setInFetch] = useState(false)
  const [txId, setTxId] = useState('')
  const [selectedSharable, setSelectedSharable] = useState(mySharableCollections[0])
  const [defaultCollectionName, setDefaultCollectionName] = useState('Sharable collection 01')
  const [editFieldValue, setEditFieldValue] = useState()
  const [showEditField, setShowEditField] = useState(false)
  // handle select
  const onChange = (e) => {
    const value = e.target.value
    const selected = mySharableCollections.find((val) => { return val.publicId === value })
    setSelectedSharable(selected)
  }
  // handle submit
  const onSubmit = async () => {
    try {
      // show spinner
      setInFetch(true)

      let sharableToSend = selectedSharable

      // if not sharable collection exist
      // create a default sharable collection
      if (!mySharableCollections.length) {
        const newCollection = await newSharableCollection(defaultCollectionName)
        sharableToSend = newCollection
        dispatch(pushSharable(newCollection))
        setSelectedSharable(newCollection)
      }

      // export token
      const txId = await exportProjectToken(
        token.tokenId,
        sharableToSend.bchAddress,
        collection._id
      )
      if (!txId) {
        throw new Error('Token cant be exported')
      }

      // delete token from redux store ( delete token from collection view )
      dispatch(deleteCollectionToken({ collectionId: collection._id, token }))

      // add token to map object in  redux store ( add token to sharable view )
      dispatch(addTokenToCollection({ collectionId: sharableToSend.publicId, token }))

      // if sharable tokens are already loaded , then add this token to sharable tokens array
      if (sharableToSend.tokens) {
        const tokens = [...sharableToSend.tokens, token]
        const sharableCollection = Object.assign({}, sharableToSend)
        sharableCollection.tokens = tokens
        dispatch(editSharable(sharableCollection))
      }

      setTxId(txId)
      setInFetch(false)
      toast.success('Success!')
    } catch (error) {
      console.warn('Error in sharableModal/submit()', error)
      setInFetch(false)
      toast.error('Error! : ' + error.message)
    }
  }

  // update text field
  const onChangeCollectionName = async (e) => {
    try {
      const value = e.target.value

      setDefaultCollectionName(value)
      setEditFieldValue(value)
    } catch (error) {
      console.warn('Error in sharableModal/onEditcollectionName()', error)
    }
  }
  // Update collection name
  const onEditCollectionName = async () => {
    try {
      if (mySharableCollections.length) {
        console.log('updating sharable collection name')
        setInFetch(true)

        const sharable = {
          publicId: selectedSharable.publicId,
          collectionLabel: editFieldValue
        }
        const result = await updateSharableCollection(sharable)
        dispatch(addSharables(result.userSharableCollections))
      }
      await sleep(500)
      setShowEditField(false)
      setInFetch(false)
    } catch (error) {
      toast.error('Error! : ' + error.message)
      setInFetch(false)
    }
  }
  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }
  return (
    <div className={sharableMStyles.container}>

      {!inFetch && txId && (
        <div className={sharableMStyles.successContainer}>
          <img className={sharableMStyles.check} src={check} alt='success icon' />
          <h2>NFT export successful</h2>
          <span>Check out your transaction on our block explorer:</span>
          <a
            href={`${TOKEN_EXPLORER}transactions/?txid=${txId}`}
            target='_blank'
            without
            rel='noreferrer'
            className={sharableMStyles.txid}
          >
            TXID: <br /> {txId}
          </a>
        </div>
      )}
      {!inFetch && !txId &&
        <>
          <h3>
            Export <span>{token.name}</span> to a Sharable Collection
          </h3>
          {selectedSharable && (
            <p>
              This token now will be visible to showcase on{' '}
              <span>{selectedSharable.collectionLabel}</span>
            </p>
          )}
          <hr />
          <div className={sharableMStyles.selectArea}>
            {mySharableCollections.length > 0 && !showEditField && (
              <select onChange={onChange} value={selectedSharable.publicId}>
                {mySharableCollections.map((val, i) => {
                  return <option key={`select-sharable-${i}`} value={val.publicId}>{val.collectionLabel}</option>
                })}
              </select>
            )}

            {mySharableCollections.length === 0 && !showEditField && (
              <select onChange={onChange} defaultValue='default-sharable'>
                <option key='default-sharable' value='default-sharable'>{defaultCollectionName}</option>
              </select>
            )}
            {showEditField && (
              <input
                id='newcollection'
                name='newcollection'
                type='text'
                defaultValue={mySharableCollections.length ? selectedSharable.collectionLabel : defaultCollectionName}
                onChange={onChangeCollectionName}
                placeholder='New sharable collection name'
              />
            )}
            <Tooltip
              title='Edit collection name'
              position='top-start'
              trigger='mouseenter'
              size='small'
              inertia='true'
              theme='dark'
            >
              {!showEditField && <img className={sharableMStyles.editBtn} src={edit} alt='edit icon' onClick={() => setShowEditField(true)} />}
              {showEditField && <img className={sharableMStyles.editBtn} src={xMark} alt='close icon' onClick={() => setShowEditField(false)} />}

            </Tooltip>
            {!showEditField && (
              <button
                className={sharableMStyles.submitBtn}
                onClick={onSubmit}
              >Submit
              </button>
            )}
            {showEditField && (
              <button
                className={sharableMStyles.submitBtn}
                onClick={onEditCollectionName}
              > Edit
              </button>
            )}
          </div>
          <hr />
        </>}
      {!inFetch && (
        <button
          className={sharableMStyles.closeBtn}
          onClick={close}
        >
          Close
        </button>
      )}
      {inFetch && (
        <PropagateLoader
          color='#ffffff'
          loading={inFetch}
          size={5}
          cssOverride={{
            display: 'block',
            textAlign: 'center',
            marginBottom: '2.5em',
            marginTop: '2.5em'
          }}
          speedMultiplier={1}
        />
      )}
    </div>
  )
}
